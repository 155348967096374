import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { UserAgent, Validation } from '@mirinae/js-utils';
import { classes } from '@mirinae/react-ui';
import { UserContext, httpAPI } from '@mirinae/react-auth';

import GlobalContext from '@mirinae/explorer/modules/defines/contexts';
import { apiPaths, explorerPaths } from '@mirinae/shared/defines/paths';
import { loadUrlParams } from '@mirinae/shared/contexts/urlParams';
import Localization from '@mirinae/shared/il8n';
import GTM from '@mirinae/shared/apis/GoogleAnalytics';
import FeedbackComponent from '@mirinae/react-ui/components/userFeedback/FeedbackComponent';

const rison = require('rison');

const FeedbackContainer = ({ type }) => {
    const [globalData, setGlobalData] = useContext(GlobalContext);
    const [userContext] = useContext(UserContext);
    const navigate = useNavigate();

    const typeData = [Localization['fb-suggestion'], Localization['fb-correction'], Localization['fb-question'], Localization['fb-other']];
    const location = useLocation();
    const urlParams = loadUrlParams(location.search);
    const uiState = urlParams.ui && rison.decode(urlParams.ui);
    const user = userContext.user._id;
    const { name, email } = userContext.user;
    const [initData, setInitData] = useState({});
    const [errors, setErrors] = useState({
        email: '',
        name: '',
        correcting: false,
    });
    const [sendingState, setSendingState] = useState(false);

    const handleClose = () => {
        if (type === 'page') {
            if (window.history.length < 2) { // what is this? (budleigh)
                // this is from support footer link
                navigate(explorerPaths.home);
            } else {
                navigate(-1);
            }
        } else {
            setGlobalData({ ...globalData, displayFeedback: 0 });
        }
    };
    const validate = (data) => {
        const currentErrors = {};

        if (!data.email) {
            currentErrors.email = Localization['fe-required'];
        } else if (Validation.invalidEmail(data.email)) {
            currentErrors.email = Localization['fe-invalidEmail'];
        } else {
            currentErrors.email = '';
        }

        if (!data.name) {
            currentErrors.name = Localization['fe-required'];
        } else {
            currentErrors.name = '';
        }

        currentErrors.correcting = Boolean(currentErrors.email || currentErrors.name);
        setErrors(currentErrors);

        return !currentErrors.correcting;
    };
    const sendFeedback = (feedback) => {
        if (!feedback.feedback || feedback.feedback.trim() === '') return;
        if (!validate(feedback)) return;
        setSendingState(true);

        httpAPI('', apiPaths.sendFeedback, { data: feedback })
            .then(() => {
                setTimeout(() => {
                    setSendingState(false);
                    handleClose();
                }, 1000);
                GTM.userAction('send', { page: location.pathname.replace('/', ''), type: feedback.type }, 'explorer');
            })
            .catch(() => {
                // console.log('error');
                handleClose();
            });
    };

    useEffect(() => {
        
        const update = {
            context: { uiState, urlParams },
            user,
            name,
            email: email && email.match(/privaterelay.appleid.com/) ? '' : email,
            type: typeData[2],
        };
        setInitData(prev => ({ ...prev, ...update }));
        GTM.pageView('feedback', { },  'explorer');
    }, []);

    if (type !== 'page' && globalData.displayFeedback !== 1) return null;
    return (
        // <Suspense fallback={LoadingSpinner}>
            <FeedbackComponent
                type={type}
                data={initData}
                errors={errors}
                typeData={typeData}
                state={sendingState}
                onClose={handleClose}
                sendFeedback={sendFeedback}
                platform={UserAgent.os.isOthers ? classes.desktop : classes.mobile}
                Localization={Localization}
            />
        // </Suspense>
    );
};

export default FeedbackContainer;