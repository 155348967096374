import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { authAPI } from '@mirinae/react-auth';
import { FooterComponent } from '@mirinae/react-gnb';
import { useAppVersionStore } from '@mirinae/stores/appVersion';

import GlobalContext from '@mirinae/explorer/modules/defines/contexts';
import Localization, { langsSelectList } from '@mirinae/shared/il8n';
import GTM from '@mirinae/shared/apis/GoogleAnalytics';
import { explorerPaths, authPaths, feedbackPaths, navigateExternal } from '@mirinae/shared/defines/paths';
import CookiePopUpContainer from '@mirinae/explorer/containers/common/CookiePopUpContainer';

const FooterContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isApp = useAppVersionStore(state => state.isApp);

    const [globalData, setGlobalData] = useContext(GlobalContext);

    const handleClickSelectLang = (newObj) => {
        const updatedPrefs = ({ ...globalData.prefs, ...newObj });
        authAPI.updatePrefs(updatedPrefs).then(() => {
            GTM.userAction('lang-select', { language: updatedPrefs.language }, 'explorer');
            window.location.reload();
        });
    };

    const handleOpenManual = () => {
        setGlobalData(prev => ({ ...prev, displayAnalyzerManual: !prev.displayAnalyzerManual }));
    };

    const handleFeedback = () => {
        if (isApp) {
            navigateExternal(explorerPaths.userFeedback);
        } else {
            setGlobalData(prev => ({ ...prev, displayFeedback: 1 }));
        }
    };

    if (location.pathname === explorerPaths.gate
        || location.pathname === explorerPaths.ocr
        || location.pathname === authPaths.signup
        || location.pathname === authPaths.login
        || location.pathname === authPaths.emailSignup
        || location.pathname === authPaths.accountConfirmed
        || location.pathname === authPaths.resetPassword
        || location.pathname === authPaths.withdraw
    ) return null;

    return (
        <>
            <CookiePopUpContainer />
            <FooterComponent
                isApp={isApp}
                versionParam={globalData.versionParam}
                localization={Localization}
                langsList={langsSelectList}
                langCode={globalData.prefs.language}
                onClickFeedback={handleFeedback}
                onClickSelectLang={handleClickSelectLang}
                onClickManual={(location.pathname === explorerPaths.home && location.search !== '') ? handleOpenManual : null}
            />
        </>
    );
};

export default FooterContainer;