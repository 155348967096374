import React from 'react';

const Affix = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '14'} height={props.height || '6'} viewBox="0 0 14 6">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#F63A3A'}>
                <path d="M1.758 4.785c3.044-3.444 7.377-3.444 10.42 0 .35.395 1.168.304 1.548-.171.38-.475.2-1.155-.17-1.548-3.85-4.088-9.326-4.088-13.175 0-.368.391-.551 1.073-.17 1.548.38.475 1.198.566 1.547.171z" transform="translate(-395 -593) translate(395 593)" />
            </g>
        </g>
    </svg>
);

export default Affix;