import React from 'react';

const Twitter = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '19'} height={props.height || '14'} viewBox="0 0 19 14">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#4DC8F1'}>
                <path d="M17.055 3.487C17.301 8.457 13.213 14 5.975 14c-2.201 0-4.25-.585-5.975-1.588 2.068.22 4.132-.299 5.77-1.464-1.705-.028-3.144-1.05-3.64-2.454.61.106 1.211.075 1.76-.06C2.015 8.092.72 6.56.763 4.924c.526.264 1.127.423 1.766.441C.793 4.314.302 2.235 1.323.647 3.245 2.785 6.117 4.192 9.357 4.34 8.79 2.129 10.64 0 13.155 0c1.12 0 2.134.429 2.845 1.116.887-.159 1.722-.453 2.475-.858-.291.826-.91 1.519-1.714 1.955.788-.085 1.54-.275 2.239-.556-.522.71-1.183 1.331-1.945 1.83" transform="translate(-487 -345) translate(487 345)" />
            </g>
        </g>
    </svg>
);

export default Twitter;