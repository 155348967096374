import React from 'react';
import styled from 'styled-components/macro';

import { palette } from '@mirinae/react-ui';

import { ReactComponent as IconClose } from '@mirinae/explorer/components/assets/icon-x.svg';

const Button = styled.button`
    position: absolute;
    width: 24px;
    height: 24px;
    border: none;
    background: none;
  
    :hover svg path {
        stroke: ${palette.gray[2]};
    }
    :disabled svg path {
        stroke: ${palette.gray[6]};
    }
    &.btn-cancel {
        right: 13px;
        top: 13px;
    }
    &.btn-clear {
        right: 12px;
        top: 12px;
    }
`;

const ClearButton = (props) => (
    <Button type="button" {...props}>
        <IconClose />
    </Button>
);

export default ClearButton;