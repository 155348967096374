import React from 'react';
import styled from 'styled-components/macro';

import { zIndex, classes, palette, CloseButton } from '@mirinae/react-ui';

const ModalPopupWrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: ${zIndex.cookieBanner + 1};
    transform: translate(-50%, -50%);
    min-height: 90px;
    padding: 15px 43px;
    border-radius: 4px;
    box-shadow: 4px 2px 16px 0 ${palette.shadow}, -4px -2px 16px 0 ${palette.white};
    border: solid 1px ${palette.gray.light};
    background-color: ${palette.white};
    color: ${palette.point.black};
    font-size: 14px;
    text-align: center;

    .${classes.desktop} & {
        width: 383px;
    }
    .${classes.mobile} & {
        max-width: 383px;
        width: calc(100% - 6px);
    }

    & > button {
        position: absolute;
        right: 5px;
        top: 5px;
    }
`;

const ModalTitle = styled.div`
    font-weight: 700;
`;

const ModalBody = styled.div`
    padding-top: 13px;
`;

const ModalPopup = (props) => (
    <ModalPopupWrapper>
        <ModalTitle>{props.title ? props.title : 'Message'}</ModalTitle>
        <CloseButton onClick={e => props.onClose && props.onClose(e)} />
        <ModalBody>{props.children}</ModalBody>
    </ModalPopupWrapper>
);

export default ModalPopup;