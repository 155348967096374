import React from 'react';
import { useCookies } from 'react-cookie';
import { ExternalLinks } from '@mirinae/js-utils';
import { cookieDomain } from '@mirinae/shared/defines/paths';
import CookiePopUp from '@mirinae/explorer/components/common/CookiePopUp';

const CookiePopUpContainer = () => {
    /*
     * cookie agreement ask only one time by browser not by user's auth info
     * so, it works not dependency with "UserContext"
     */
    const [cookies, setCookies] = useCookies(['agreed', 'welcomed']);
    if (cookies.agreed) return null;


    const handleAgree = (e) => {
        e.stopPropagation();
        setCookies('agreed', 'true', { path: '/', maxAge: 10 * 365 * 24 * 60 * 60, domain: cookieDomain });
    };

    if (cookies.agreed || !cookies.welcomed) return null;

    return <CookiePopUp link={ExternalLinks.PrivacyPolicy} onClick={(e) => handleAgree(e)} />;
};

export default CookiePopUpContainer;
