import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { palette, zIndex } from '@mirinae/react-ui';
import GlobalContext from '@mirinae/explorer/modules/defines/contexts';

import CloseButton from '@mirinae/explorer/components/exploration/input/ClearButton';
import { ReactComponent as AlertIcon } from '@mirinae/explorer/components/assets/alert.svg';
import Localization from '@mirinae/shared/il8n';
import { ExternalLinks, UserAgent } from '@mirinae/js-utils';
import { useAppVersionStore, useAppVersionStoreAndMethods } from '@mirinae/stores/appVersion';

const { os } = UserAgent;

const Popup = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${zIndex.cookieBanner + 10};
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
`;
const PopupInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    padding: 40px;
    border-radius: 10px;
    background-color: ${palette.primary.white};
    text-align: center;
    row-gap: 15px;

    @media only screen and (max-width: 390px) {
        width: 100%;
        // margin: 0 20px;
        padding: 40px 20px;
    }
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;    
    color: #525252;
`;

const Explanation = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #555555;
`;

const UpdateBtn = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    height: 44px;
    width: 100%;
    background: #87CBC8;
    border-radius: 10px;
    margin-top: 15px;
  
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
`;

const UpgradeToPP2AppPopup = () => {
    const [displayMustUpgradeApp, { setVersionData }] = useAppVersionStoreAndMethods(state => state.displayMustUpgradeApp);

    const close = (e) => {
        setVersionData({ displayMustUpgradeApp: null });
    };

    console.log(ExternalLinks.app.apple);

    return (

        <Popup>
            <PopupInner>
                <AlertIcon />
                <Title>{Localization['pp2-update']}</Title>
                <Explanation>{Localization[displayMustUpgradeApp]}</Explanation>
                <UpdateBtn href={os.isAndroid ? ExternalLinks.app.android : ExternalLinks.app.apple} onClick={close}>{Localization['pp2-btn']}</UpdateBtn>
                <CloseButton className="btn-cancel" onClick={close} />
            </PopupInner>
        </Popup>
    );
};

export default UpgradeToPP2AppPopup;