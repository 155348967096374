const { Base64Encode, Base64Decode } = require('./utils/base64');
const { os, browser, device } = require('./utils/userAgent');
const { invalidEmail, invalidPassword, isContinuousString } = require('./utils/validation');
const { strings, replaceAll } = require('./utils/strings');
const { permissions } = require('./utils/permissions');
const { ExternalLinks, isApp, explorerHost, memberHost, lessonHost, wikinaeHost, rootDomain, cookieDomain, buildExternalURL } = require('./utils/links');

const UserAgent = {
    os, browser, device
};
const Validation = {
    invalidEmail, invalidPassword, isContinuousString
};

export {
    Base64Encode, Base64Decode,
    UserAgent,
    Validation,
    strings, replaceAll,
    permissions,
    ExternalLinks, rootDomain, cookieDomain, buildExternalURL,
};