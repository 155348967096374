import React from 'react';

const Facebook = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '10'} height={props.height || '18'} viewBox="0 0 10 18">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#4460A0'}>
                <path d="M10 .127v2.84l-1.814.005c-1.422 0-1.696.628-1.696 1.546V6.55h3.387l-.44 3.179H6.49V18H2.957V9.729H0V6.55h2.957V4.206C2.957 1.484 4.743 0 7.36 0 8.607 0 9.684.088 10 .127z" transform="translate(-457 -343) translate(457 343)" />
            </g>
        </g>
    </svg>
);

export default Facebook;