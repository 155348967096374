import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import HtmlParser from 'html-react-parser';

import GlossaryContext from '@explorer/modules/contexts/glossary';
import { ClearButton, LevelButtons, palette, zIndex } from '@mirinae/react-ui';
import { os } from '@mirinae/js-utils/utils/userAgent';

const POPUP_WIDTH = 854; // same as the grammar definition popup

const popupMargin = () => (os.isOthers ? window.innerWidth / 2 - POPUP_WIDTH / 2 : 0);

// x & y coords here are relative to the '#root-page' element, the offsetParent of this popup
const GlossaryPopupWrapper = styled.div`
    position: absolute;
    left: ${props => props.windowMargin}px;
    right: ${props => props.windowMargin}px;
    top: ${props => props.y + 9}px;
    background-color: rgba(78, 96, 108, .95);
    border-radius: ${os.isOthers ? 5 : 0}px;
    padding: ${os.isOthers ? '20px 80px 20px 40px' : '20px'};
    color: ${palette.primary.white};
    z-index: ${zIndex.feedbackPopup};
  
    :before {
        content: '';
        position: absolute;
        top: -7px;
        left: ${props => props.x - popupMargin()}px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6.5px 7px 6.5px;
        border-color: transparent transparent rgba(78, 96, 108, .95) transparent;
    }
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    min-height: 17px;
    line-height: 17px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    svg {
        margin-left: 10px;
    }
`;
const Contents = styled.div`
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
`;
const ButtonArea = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    button {
        width: 100%;
        height: 100%;
    }
    svg {
        width: 10px;
        height: 10px;
        path {
            fill: ${palette.primary.white};
        }
    }
    :hover svg path {
        fill: ${palette.primary.white};
    }
`;

const GlossaryPopup = () => {
    const ref = useRef(null);
    const glossary = useContext(GlossaryContext);

    useEffect(() => {
        function handleClickOutside(e) {
            e.stopPropagation();
            if (ref.current && !ref.current.contains(e.target)) {
                glossary.showPopup(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    if (!glossary.display) return null;
    return (
        <GlossaryPopupWrapper ref={ref} x={glossary.x} y={glossary.y} windowMargin={popupMargin()}>
            <Title>
                {HtmlParser(glossary.title)}
                {/* <LevelButtons level={23} /> this is the glossary "category" that Hailie wants but is not yet in the glossary data, add when it is */}
            </Title>
            <Contents>
                {HtmlParser(glossary.text)}
            </Contents>
            <ButtonArea>
                <ClearButton onClick={() => glossary.showPopup(false)} />
            </ButtonArea>
        </GlossaryPopupWrapper>
    );
};

export default GlossaryPopup;