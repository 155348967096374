import { createContext, useState, useRef } from 'react';
import { nlpAPI } from '@mirinae/explorer/modules/apis/nlp';
import { keywordsByLetter } from '@mirinae/shared/defines/glossaryTerms';

const initState = {
    display0: false,
    display: false,
    x: 0, y: 0,
    title: '',
    text: '',
    history: new Set(),
};

export function useGlossaryContext() {
    const [state, setState] = useState(initState);

    const updateState = (update) => {
        setState(prev => ({ ...prev, ...update }));
    };

    const getGlossarySpans = (sourceText, glossaryHistory = new Set()) => {
        // returns a list of spans of substrings of text, some annotated with call details to a retrieve glossary entry for the text in its span
        const spans = [];
        const text = `${sourceText.toLowerCase()} `;
        let si = 0; let wi = 0; let ei = 0;
        while (wi < text.length) {
            const kwds = keywordsByLetter[text[wi]];
            if (kwds)
                for (let i = 0; i < kwds.length; i += 1) {
                    if (text.length - wi > kwds[i].length && text.startsWith(kwds[i], wi)) {
                        if (wi - si > 1)
                            spans.push({ text: sourceText.substring(si, wi) });
                        ei = wi + kwds[i].length;
                        while (ei < text.length && text[ei].match(/\w/)) ei += 1;
                        const phrase = sourceText.substring(wi, ei);
                        if (glossaryHistory.has(kwds[i]))
                            spans.push({ text: phrase });
                        else {
                            spans.push({ text: phrase, glossaryKey: kwds[i] });
                            glossaryHistory.add(kwds[i]);
                        }
                        wi += kwds[i].length;
                        si = ei;
                        break;
                    }
                }
            while (wi < text.length && !text[wi].match(/\s/)) wi += 1;
            while (wi < text.length && text[wi].match(/\s/)) wi += 1;
        }
        if (wi - si > 1)
            spans.push({ text: sourceText.substring(si, wi) });
        return spans;
    };

    const getGlossaryEntry = (keyword) => nlpAPI.getGlossaryEntry(keyword);

    const keywordClick = ({ event, keyword, container, dx, dy }) => {
        if (state.display) {
            updateState({ display: false });
        } else {
            const containerEl = container || event.target;
            const glossaryKey = keyword || containerEl.getAttribute('data-kw');
            // get coords of center-bottom of glossay word span relative to the offset-parent of the glossary popup (id="page-root" in <SingleLayout> in app.js)
            const pageRootBR = document.getElementById('explorer-app-root').getBoundingClientRect();
            const elBR = containerEl.getBoundingClientRect();
            const [x, y] = container
                ? [elBR.left + dx - pageRootBR.left, elBR.top + dy - pageRootBR.top]
                : [((elBR.left - pageRootBR.left) + elBR.right) / 2, elBR.bottom - pageRootBR.top];
            // get glossary entry & display
            nlpAPI.getGlossaryEntry(glossaryKey, { exact: true }).then(response => {
                const resp = response.response.find(ge => ge.keyword.toLowerCase() === glossaryKey.toLowerCase()) || response.response[0];
                console.log('keyword click', keyword, x, y);
                updateState({
                    display: true,
                    x, y,
                    title: resp.keyword,
                    text: resp.definition,
                });
            });
        }
    };

    const textClick = ({ event, text, glossaryHistory, container, dx, dy }) => {
        // click on text possibly containing glossary keyword, scan for first (?) and show glossary if found
        const keywordSpan = getGlossarySpans(text, glossaryHistory).filter(span => span.glossaryKey)[0];
        if (keywordSpan) {
            keywordClick({ event, keyword: keywordSpan.glossaryKey, container, dx, dy });
        }
    };

    return {
        ...state,
        update: updateState,
        clear: () => setState(initState),
        showPopup: (state) => updateState({ display: state }),
        getGlossarySpans,
        getGlossaryEntry,
        keywordClick,
        textClick,
    };
}

const GlossaryContext = createContext(null);

export default GlossaryContext;
