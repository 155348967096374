import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { zIndex, palette, breakPoint, IconMirinae } from '@mirinae/react-ui';
import GTM from '@mirinae/shared/apis/GoogleAnalytics';

const MobileStoreBannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${zIndex.cookieBanner + 100};
    background-color: ${palette.white};
    border-radius: 40px 40px 0 0;
    box-shadow: 4px 2px 16px ${palette.shadow};
    transition: transform 0.3s ease-out;
    &.hide {
        transform: translateY(100%);
    }
`;
const BannerUpper = styled.div`
    display: flex;
    align-items: ${props => (props.theme === 'forWeb' ? 'center' : 'flex-start')};
    width: 100%;
    max-width: ${props => (props.theme === 'forWeb' ? '310px' : '334px')};
    margin-bottom: 20px;
    padding-top: 40px;

    svg {
        flex-shrink: 0;
        margin-right: 20px;
    }

    @media only screen and (max-width: ${breakPoint.xs}) {
        max-width: initial;
        padding: 20px 20px 0;
        svg {
            margin-right: 10px;
        }
    }
`;
const BannerTexts = styled.div`
    ${props => (props.theme === 'forWeb' ? `
        font-size: 18px;
    ` : `
        strong {
            display: block;
            margin-bottom: 5px;
            color: ${palette.green.middle};
            font-weight: 700;
            font-size: 18px;
        }
        p {
            color: ${palette.point.gray};
            font-size: 16px;
            font-weight: 400;
        }
    `)}
`;
const BannerLower = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${props => (props.theme === 'forWeb' ? '310px' : '334px')};
    padding-bottom: 40px;

    @media only screen and (max-width: ${breakPoint.xs}) {
        max-width: initial;
        padding: 0 20px 20px;
    }
`;
const ButtonMoveStore = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    margin-bottom: 15px;
    border-radius: 20px;
    background-color: ${palette.green.middle};
    font-size: 14px;
    color: ${palette.white};
    text-align: center;
`;
const ButtonUseWeb = styled.button`
    color: ${palette.point.gray};
    font-size: 12px;
`;

const MobileStoreBanner = ({ children, theme, linkText, ignoreText, link, onCancel, onConfirm }) => {
    const $layer = useRef();
    const handleUseWeb = (e) => {
        if (onCancel) onCancel(e);
        $layer.current.classList.add('hide');
    };
    const handleUseApp = (e) => {
        if (onConfirm) onConfirm(e);
        GTM.userAction('click-download', { }, 'explorer');
    };

    useEffect(() => {
        $layer.current.classList.remove('hide');
    }, []);

    return (
        <MobileStoreBannerWrapper className="mobile-banner hide" ref={$layer}>
            <BannerUpper theme={theme}>
                <IconMirinae />
                <BannerTexts theme={theme}>
                    {children}
                </BannerTexts>
            </BannerUpper>
            <BannerLower theme={theme}>
                <ButtonMoveStore href={link} target="_blank" onClick={handleUseApp}>{linkText}</ButtonMoveStore>
                <ButtonUseWeb type="button" onClick={handleUseWeb}>{ignoreText}</ButtonUseWeb>
            </BannerLower>
        </MobileStoreBannerWrapper>
    );
};

export default MobileStoreBanner;