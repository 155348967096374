import React, { useState, useContext, lazy, Suspense } from 'react';
// import Helmet from 'react-helmet';

import { UserContext } from '@mirinae/react-auth';
import GlobalContext from '@explorer/modules/defines/contexts';
import { TutorialContext, useTutorialContext } from '@explorer/modules/contexts/tutorial';
import GlossaryContext, { useGlossaryContext } from '@explorer/modules/contexts/glossary';
import SingleLayout from '@explorer/components/layout/SingleLayout';
import HeaderContainer from '@explorer/containers/common/HeaderContainer';
import RouterWrapper from '@explorer/components/layout/RouterWrapper';
import FeedbackContainer from '@explorer/containers/feedback/FeedbackContainer';
import ModalPopupContainer from '@explorer/containers/common/ModalPopupContainer';
import GlossaryPopup from '@explorer/components/glossary/GlossaryPopup';
import MobileStoreBannerContainer from '@explorer/containers/common/MobileStoreBannerContainer';
import UpgradeProPopup from '@explorer/components/pro/UpgradeProPopup';
import UpgradeToPP2AppPopup from '@explorer/components/common/UpgradeToPP2AppPopup';
import FooterContainer from '@explorer/containers/common/FooterContainer';
// import WikinaePromoPopup from '@explorer/components/common/WikinaePromoPopup';
// import withBase from '@mirinae/shared/components/appBase';
// import HangulDayPromoPopup from '@explorer/components/common/HangulDayPromoPopup';
import Lessons1dot0Promo from '@explorer/components/common/Lessons1dot0Promo';
import { useAppVersionStore } from '@mirinae/shared/stores/appVersion';

const EmbeddedExploration = lazy(() => import(/* webpackChunkName: "EmbeddedExploration" */ '@mirinae/explorer/pages/EmbeddedExploration'));

import ExplorerRoutes from './explorerRoutes';
import { useProAccessStore } from '@mirinae/stores/proAccessStore';

const defaultAnalysisPrefs = {
    'mode' : 'normal',
    'fontSize' : 'normal',
    'speed' : 'normal',
    'gender' : 'female',
    'romanization' : false,
};


window.setAppPriceLocalStorage = function(app_currency_code, app_currency_symbol,app_raw_price_annual,app_raw_price_month) {
    // alert("mainhello ::" + app_raw_price_annual);
    localStorage.setItem("app_currency_code",app_currency_code);
    localStorage.setItem("app_currency_symbol",app_currency_symbol);
    localStorage.setItem("app_raw_price_annual",app_raw_price_annual);
    localStorage.setItem("app_raw_price_month",app_raw_price_month);
}



const Explorer = () => {
    const [userContext, ] = useContext(UserContext);
    const { user } = userContext;
    const { isApp, versionParam, appVersion, isProEnabledVersion, isPricePlan3PlusEnabled, displayMustUpgradeApp } = useAppVersionStore();
    const displayUpgradeToPro = useProAccessStore(state => state.displayUpgradeToPro);

    const [globalData, setGlobalData] = useState({
        displayFeedback: 0,
        displayAnalyzerManual: false,
        modalPopup: {
            show: false,
            title: '',
            message: '',
        },
        prefs: { ...user.preferences },
        versionParam,
        appVersion,
        isProEnabledVersion,
        isPricePlan3PlusEnabled,
    });

    const tutorialState = useTutorialContext();
    const glossaryState = useGlossaryContext();

 
    
    
    
       


    // this check appears to be needed on every entry; prefs change infrequently, so the inequality guard should prevent render recursions.
    if (!_.isEqual(user.preferences, globalData.prefs)) {
        if (!user.preferences.analysis || Object.keys(user.preferences.analysis).length < 5) // repair corrupted analysis prefs
            user.preferences.analysis = { ...defaultAnalysisPrefs, ...user.preferences.analysis };
        console.log('Explorer update prefs from userContext', user.preferences.language, user.preferences);
        setGlobalData(prev => ({ ...prev, prefs: { ...user.preferences } }));
    }

    return (
        <GlobalContext.Provider value={[globalData, setGlobalData]}>
            <TutorialContext.Provider value={tutorialState}>
                <GlossaryContext.Provider value={glossaryState}>
                    <SingleLayout>
                        <HeaderContainer />
                        <RouterWrapper>
                            <ExplorerRoutes />
                        </RouterWrapper>
                        <FeedbackContainer />
                        <ModalPopupContainer />
                        <GlossaryPopup />
                        {/* download app banner */}<MobileStoreBannerContainer isApp={isApp} />
                        {/* upgrade pro popup */}{ displayUpgradeToPro && <UpgradeProPopup /> }
                        {/* upgrade pro popup */}{ displayMustUpgradeApp && <UpgradeToPP2AppPopup /> }
                        {/* wikinae promo popup <WikinaePromoPopup isApp={isApp} /> */}
                        {/* hangul day promo popup }<HangulDayPromoPopup user={user} /> */}
                        {/* xmas promo popup <XmasPromoPopup user={user} /> */}
                        {/* hangul day promo popup */}{ <Lessons1dot0Promo user={user} /> }
                        <FooterContainer />
                    </SingleLayout>
                </GlossaryContext.Provider>
            </TutorialContext.Provider>
        </GlobalContext.Provider>
    );
};

export default Explorer;
