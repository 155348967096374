import React from 'react';

const Spacing = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '11'} height={props.height || '8'} viewBox="0 0 11 8">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#F63A3A'} fillRule="nonzero">
                <path d="M4.643 6.948L1.201 3.132C.966 2.9.834 2.586.834 2.258c0-.328.132-.643.367-.874C1.689.9 2.48.9 2.968 1.384l2.497 2.878c.066.065.125.065.192 0l2.409-2.878c.488-.483 1.28-.483 1.767 0 .235.231.367.546.367.874 0 .328-.132.642-.367.874L6.477 6.948c-.52.514-1.314.514-1.834 0z" transform="translate(-539 -257) translate(539 257)" />
            </g>
        </g>
    </svg>
);

export default Spacing;