import React from 'react';

const Trash = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '11'} height={props.height || '14'} viewBox="0 0 11 14">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'} fillRule="nonzero">
                <path d="M6.604.615h-1.79V.488c0-.414-.3-.488-.667-.488H2.896c-.366 0-.664.074-.664.488v.127H.396C.29.615.19.662.116.746.04.831 0 .946 0 1.065v.485C0 1.8.177 2 .396 2h6.208C6.823 2 7 1.799 7 1.55v-.485c0-.249-.177-.45-.396-.45zM6.605 3H.393c-.113.001-.22.059-.295.16-.074.1-.109.232-.095.364L.64 9.198c.05.456.378.8.768.802h4.18c.393 0 .723-.344.772-.802l.637-5.674c.014-.133-.02-.266-.096-.366-.075-.1-.183-.158-.296-.158z" transform="translate(-600 -590) translate(600 590) translate(2 2)" />
            </g>
        </g>
    </svg>
);

export default Trash;