import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FeatureFlagContext } from '@mirinae/shared/contexts/featureFlags';

const rison = require('rison');

const queryParamEncoders = {
    uiState: { pk: 'ui', encoder: rison.encode },
    featureFlags: { pk: 'ff', encoder: urlEncodeFlags },
};

const queryParamDecoders = {
    'ui': rison.decode,
    'ff': urlDecodeFlags,
};

export const UrlParamContext = createContext({});

export function loadUrlParams(search) {
    const params = {};
    const regex = new RegExp('[?&]([a-z_0-9A-Z]+)=([^&#]*)', 'g');

    let tempArr;
    // eslint-disable-next-line no-cond-assign
    while ((tempArr = regex.exec(search)) != null) {
        params[tempArr[1]] = decodeURIComponent(tempArr[2]);
    }
    return params;
}

export function loadAndDecodeUrlParams(search) {
    const params = loadUrlParams(search);
    Object.getOwnPropertyNames(params).forEach(qpk => {
        const decoder = queryParamDecoders[qpk];
        if (decoder)
            params[qpk] = decoder(params[qpk]);
    });
    return params;
}

// note that the following has to be called in each page that wants to provide UrlParamContext to its nested
//   components, see eg pages/exploration.js for example.  This is because location & history only available
//   with Router-controlled components, which are individual routable pages at the top level

export function useUrlParamContext(featureFlags) {
    // establishes UrlParamContext & URL history change listener
    const location = useLocation();
    const [urlParams, setUrlParams] = useState(loadUrlParams(location.search));
    const [, setFeatureFlags] = featureFlags;

    useEffect(() => {
        const updateFeatureFlags = (urlParams) => {
            // we also set here changes in ff= FeatureFlag params, since the FeatureFlag popup is
            //  inside the HeaderContainer and so outside the UrlParamContext
            const newFF = urlParams.ff && urlDecodeFlags(urlParams.ff);
            if (newFF) {
                setFeatureFlags({ ...newFF, addToURL: true });
            }
        };
    
        const urlParams = loadUrlParams(location.search);
        setUrlParams(urlParams);
        updateFeatureFlags(urlParams);
    }, [location])

    // we also set up a useEffect triggered by urlParam
    return urlParams;
}

// --------- URL builder ------------------

export function buildURL(spec) {
    // build page URL from given spec { [pathname: xxx], [uiState: {}], [featureFlags: {}], [<other>: <text>...]}
    //  if pathname missing, current location pathname is used, others are queryparm elements
    //  uiState & featureFlags objects special cases with their own encodings
    const path = spec.pathname || document.location.pathname;
    // build new queyy-string
    let newQueryParams = '';
    Object.getOwnPropertyNames(spec).sort().forEach(qpk => {
        if (qpk !== 'pathname') {
            let pk = qpk;
            let qp = spec[qpk];
            if (qp === null || qp === undefined)
                return;
            const en = queryParamEncoders[pk];
            if (en) {
                pk = en.pk;
                qp = en.encoder(qp);
            }
            if (qp.length > 0)
                newQueryParams += `${pk}=${qp}&`;
        }
    });
    //
    const url = `${path}${path.includes('?') ? '&' : '?'}${newQueryParams.replace(/&$/, '')}`;
    // console.log('buildURL = ', url);
    return url;
}

// --------- uiState URL codecs --------

export function getUIState(ctx) {
    // returns uiState object encoding current UI state, used to form page history URLs and to store in
    //   persisted exploration-history bookmarks
    const uiState = ctx.bm ? {} : null;
    if (ctx.bm) {
        uiState.bm = ctx.bm;
        if (ctx.selectedSentence) uiState.si = ctx.selectedSentence.index;
        if (ctx.selectedPattern && ctx.selectedPOS) uiState.sp = ctx.selectedPOS.index;
        if (ctx.selectedWord) uiState.sw = ctx.selectedPOS.index;
        if (typeof ctx.selectedIdiomIndex === 'number') uiState.sd = ctx.selectedIdiomIndex;
        if (ctx.selectedPatternTab) uiState.spt = ctx.selectedPatternTab;
        if (typeof ctx.selectedSensePOSIndex === 'number') uiState.swsp = ctx.selectedSensePOSIndex;
        if (typeof ctx.selectedSenseIndex === 'number') uiState.sws = ctx.selectedSenseIndex;
        if (typeof ctx.selectedMeaningIndex === 'number') uiState.sm = ctx.selectedMeaningIndex;
    }
    //
    return uiState;
}

export function decodeURLtoJson(ctx) {
    const decodedURL = {};
    decodedURL.pageURL = ctx.split('#')[1];
    // decoding rison URL parts (testing)
    if (decodedURL.pageURL.includes('?')) {
        decodedURL.queryState = decodedURL.pageURL.split('?')[1];
        if (decodedURL.queryState.includes('&')) {
            decodedURL.ui = decodedURL.queryState.split('&')[1].split('(')[1].split(')')[0];
        } else if (decodedURL.queryState.includes('text')) {
            decodedURL.text = decodedURL.queryState.split('=')[1];
        } else { 
            decodedURL.ui = decodedURL.queryState.split('(')[1].split(')')[0]; 
        }
    }
    return decodedURL;
}

export function setUIState(uiState, analysisUpdate) {
    // restablish SelectionContext setting updates from given (incoming) uiState & latest analysis store update
    const selectedSentence = analysisUpdate.analysis.sentences[uiState.si || 0];
    const ctxUpdate = {
        bm: uiState.bm,
        selectedSentence,
        selectedPattern: null,
        selectedWord: null,
        selectedPOS: null,
        selectedSenseIndex: null,
        selectedSensePOSIndex: null,
        selectedMeaningIndex: null,
        selectedIdiomIndex: null,
        selectedPatternTab: uiState.spt,
    };
    let pos = null;
    if ('sp' in uiState) {
        pos = selectedSentence.mappedPosList[uiState.sp];
        ctxUpdate.selectedPattern = selectedSentence.patterns[pos.pattern];
    } else if ('sw' in uiState) {
        pos = selectedSentence.mappedPosList[uiState.sw];
        ctxUpdate.selectedWord = selectedSentence.displayDefs[pos.morph];
        if ('sws' in uiState && 'swsp' in uiState) {
            ctxUpdate.selectedSensePOSIndex = uiState.swsp;
            ctxUpdate.selectedSenseIndex = uiState.sws;
        }
        if ('sm' in uiState) {
            ctxUpdate.selectedMeaningIndex = uiState.sm;
        }
    } else if ('sd' in uiState) {
        const idiom = selectedSentence.idioms[uiState.sd];
        ctxUpdate.selectedIdiomIndex = uiState.sd;
        ctxUpdate.selectedPattern = selectedSentence.patterns[idiom.pattern];
    }
    ctxUpdate.selectedPOS = pos;
    // return updates for SelectContext extracted from uiState
    return ctxUpdate;
}

// ------- Feature flag URL codecs  --------

export function urlEncodeFlags(flags) {
    return (
        // encode feature-flags for placement in URL
        flags.addToURL
            ? `${flags.endOfPagePapagoTranslation ? 'e' : ''}`
                + `${flags.multiLineInputArea ? 'm' : ''}`
                + `${flags.incrementalAnalysis ? 'i' : ''}`
                + `${flags.persistentInputText ? 'p' : ''}`
                + `${flags.displayAllParseTrees ? 'd' : ''}`
            : ''
    );
}

export function urlDecodeFlags(efs) {
    // decode URL flag encoding
    const ef = {};
    for (let i = 0; i < efs.length; i += 1)
        ef[efs[i]] = true;
    return {
        endOfPagePapagoTranslation: ef.e || false,
        multiLineInputArea: ef.m || false,
        incrementalAnalysis: ef.i || false,
        persistentInputText: ef.p || false,
        displayAllParseTrees: ef.d || false,
    };
}

export function flagsQueryString(flags) { return (flags.addToURL ? `&ff=${urlEncodeFlags(flags)}` : ''); }