import React from 'react';

const GreenCheck = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '12'} height={props.height || '10'} viewBox="0 0 12 10">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#00d7d0'} fillRule="nonzero">
                <path d="M3.215 9.579L.406 6.844C.146 6.59 0 6.247 0 5.889s.146-.702.406-.955c.542-.527 1.42-.527 1.961 0l1.76 1.711c.073.072.192.072.266 0l5.24-6.25c.541-.527 1.419-.527 1.96 0 .26.253.407.597.407.955s-.146.701-.406.954L5.303 9.58c-.577.561-1.512.561-2.088 0z" transform="translate(-355 -725) translate(355 725)" />
            </g>
        </g>
    </svg>
);

export default GreenCheck;