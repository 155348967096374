import React from 'react';
import styled from 'styled-components/macro';

import { breakPoint, classes, size } from '@mirinae/react-ui';
// import { UserAgent } from '@mirinae/js-utils';

const LayoutWrapper = styled.div`
    position: relative;
    width: 100%;

    .${classes.desktop} & {
        // height: 100vh;
        min-height: calc(100vh - 331px);

        @media only screen and (min-width: ${breakPoint.lg}) {
            min-width: ${size.width};
        }
    }
    .${classes.desktop}#kpop & {
        min-width: 900px;

        @media only screen and (max-width: ${breakPoint.lg}) {
            min-width: 100%;
        }
    }
    .${classes.mobile} & {
        height: 100vh;
    }
    .${classes.parseTree} {
        background-color: #fff;
    }
`;
// const MoveTopButtonWrapper = styled.div`
//     position: fixed;
//     bottom: 15px;
//     right: 15px;
//     pointer-events: none;
// `;

const SingleLayout = props => {
    // const [showTopButton, setShowTopButton] = useState(false);

    // const handleScroll = () => {
    //     if (UserAgent.os.isOthers) return;
    //     if (window.innerHeight * 0.5 < window.scrollY) {
    //         setShowTopButton(true);
    //     } else {
    //         setShowTopButton(false);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, true);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <LayoutWrapper id="explorer-app-root" >
            {props.children}
            {/* {!UserAgent.os.isOthers && (
                <MoveTopButtonWrapper>
                    <MoveTopButton isVisible={showTopButton} />
                </MoveTopButtonWrapper>
            )} */}
        </LayoutWrapper>
    );
};

export default SingleLayout;