import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useCookies } from 'react-cookie';

import { UserAgent } from '@mirinae/js-utils';
import { palette, zIndex } from '@mirinae/react-ui';

import closeXPNG from '@explorer/components/assets/Manul-X.svg';
import { cookieDomain, membersPaths, wikinaePaths } from '@mirinae/shared/defines/paths';
import { getUserStatus } from '@mirinae/defines/datas';

const { os } = UserAgent;
const isMobile = !os.isOthers;

const PromoBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100vh;
    display: flex;
    z-index: ${zIndex.tutorial};
    background-color: rgba(0, 0, 0, 0.4);
`;

const HangulDayPromoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: ${zIndex.tutorial};
    top: 50%;
    left: 50%; 
    transform: translate(-50%, calc(-50% - ${props => isMobile ? 0 : props.height / 2}px));
    background: url(/assets/explorer/lessons-1-0-promo.png) top left / cover;
    // box-shadow: 0 0 15px rgba(196, 196, 196, 0.48);
    border-radius: 20px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    pointer-events: none;
`;

const CloseX = styled.button`
    position: absolute;
    top: ${isMobile ? '10px' : '15px'};
    right: ${isMobile ? '10px' : '15px'};
    width: 40px;
    height: 40px;
    background: url(${closeXPNG}) no-repeat 50%/cover;
`;

const SignMeUp = styled.a`
    display: flex;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    background: transparent;
    margin-top: ${props => props.margin}px;
`;

const Lessons1dot0Promo = ({ user }) => {
    const [cookies, setCookie] = useCookies(['lessons10promoed']);
    const [display, setDisplay] = useState(false);
    // ensure it fits in small screens...  1080 x 1420
    const h2w = 550 / 418;
    const hr = (window.innerHeight  - 170) / 550, wr = (window.innerWidth - 20) / 418;
    const sr = Math.min(1.0, hr, wr);
    const h = h2w * sr * 418, w = sr * 418, m = sr * 490;

    const onClose = (e) => {
	    setCookie('lessons10promoed', 'true', { path: '/', maxAge: 10 * 365 * 24 * 60 * 60, domain: cookieDomain });
        setDisplay(false);
    };

    useEffect(() => {
    	setDisplay(
            cookies.lessons10promoed !== 'true'
            && cookies.landed === 'true'
            && !window.location.pathname.match(/\/landing$/)
            && !cookies.lessons10promoed && getUserStatus(user) !== 'active');
    }, []);

    return display && (
        <>
            <PromoBackground />
            <HangulDayPromoWrapper width={w} height={h}>
                <CloseX type="button" onClick={onClose} />
                <SignMeUp href={membersPaths.plan} margin={m} target="_blank" onClick={onClose}></SignMeUp>
            </HangulDayPromoWrapper>
        </>
    );
};

export default Lessons1dot0Promo;
