import React from 'react';

const Google = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '20'} height={props.height || '20'} viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
                <path fill="#4285F4" d="M19.981 10.234c0-.677-.056-1.359-.175-2.025h-9.597v3.839h5.496c-.228 1.238-.961 2.333-2.034 3.029v2.49h3.279c1.925-1.735 3.031-4.3 3.031-7.333z" transform="translate(-526 -342) translate(526 342)" />
                <path fill="#34A853" d="M10.21 19.974c2.743 0 5.057-.882 6.743-2.406l-3.278-2.491c-.912.608-2.09.952-3.462.952-2.654 0-4.905-1.754-5.712-4.113H1.118v2.568c1.727 3.366 5.245 5.49 9.091 5.49z" transform="translate(-526 -342) translate(526 342)" />
                <path fill="#FBBC04" d="M4.497 11.916c-.426-1.238-.426-2.579 0-3.817V5.53h-3.38c-1.442 2.817-1.442 6.136 0 8.953l3.38-2.568z" transform="translate(-526 -342) translate(526 342)" />
                <path fill="#EA4335" d="M10.21 3.982c1.45-.022 2.852.513 3.902 1.494l2.905-2.846C15.177.938 12.737.007 10.209.037 6.363.037 2.845 2.16 1.118 5.53L4.498 8.1c.803-2.363 3.057-4.117 5.711-4.117z" transform="translate(-526 -342) translate(526 342)" />
            </g>
        </g>
    </svg>
);

export default Google;