import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const defaultConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'imageUpload', '|', 'undo', 'redo'],
};
// eliding 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed',

const Editor = ({ text, placeholder, onTextChanged, config }) => {

    return (
        <CKEditor
            editor={ClassicEditor}
            placeholder={placeholder}
            data={text}
            config={{ ...defaultConfig, ...config }}
            onChange={(event, editor) => {
                onTextChanged(editor.getData());
            }}
        />
    );
};

export default Editor;