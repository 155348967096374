import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Draggable from 'react-draggable';
import ReactHtmlParser from 'html-react-parser';

import SelectForm from '../form/SelectForm';
import IconDots from '../icons/Dots';
import CloseButton from '../buttons/CloseButton';
import DefaultButton from '../buttons/DefaultButton';
import { palette, zIndex, breakPoint, classes } from '../../defines/styles';
import Editor from './Editor';

const FeedbackWrapper = styled.div`
    .${classes.desktop} & {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${zIndex.feedbackPopup};
        background-color: rgba(189, 189, 189, 0.3);
    }
    .${classes.mobile} & {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: ${zIndex.feedbackPopup};
        display: flex;
        align-items: center;
        background-color: ${palette.white};
        touch-action: none;

        & > div { // drag element
            transform: none !important;;
        }
    }
`;
const MobileDivision = styled.div`
    & > button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .${classes.mobile} & > button{
        width: 50px;
        height: 50px;
    }
`;
const PopupWrapper = styled.div`
    padding: 20px;
    border-radius: 4px;
    background-color: ${palette.white};

    .${classes.desktop} & {
        position: absolute;
        top: 0;
        left: 0;
        width: 680px;
        box-shadow: 4px 2px 16px 0 #c8c8c8, -4px -2px 16px 0 ${palette.white};

        @media only screen and (max-width: ${breakPoint.sm}) {
            width: 90%;
            min-width: 500px;
        }
    }
    .${classes.mobile} & {
        bottom: 0;
        width: 100%;
    }

    ${() => (/*!window.location.hostname.match(/^(app|alpha.app)\./) && */ `
        .${classes.mobile} & {
            @media only screen and (orientation: landscape) {
                display: flex;
                justify-content: space-between;

                ${MobileDivision} {
                    width: 44%;
                    &:nth-child(2) {
                        padding-top: 30px;
                    }
                }
            }
        }`
    )}
`;
const PopupTitle = styled.div`
    display: flex;
    align-items: center;
    height: 59px;
    margin: -20px -20px 0;
    padding: 20px;

    .${classes.desktop} & {
        cursor: move;
    }

    svg {
        margin-right: 5px;
    }
    strong {
        font-weight: 800;
        .${classes.desktop} & {
            font-size: 15px;
        }
        .${classes.mobile} & {
            font-size: 18px;
        }
    }
    span {
        margin-left: 5px;
        margin-top: 3px;
        color: ${palette.point.gray};
        font-size: 10px;
    }
`;
const PopupInputs = styled.div`
    display: flex;
    flex-wrap: wrap;
    .${classes.desktop} & {
        @media only screen and (max-width: ${breakPoint.sm}) {
            display: inline
        }
    }
`;
const PopupInput = styled.div`
    display: flex;
    margin-bottom: 10px;

    ${props => (props.theme === 'type' && (`
        margin-bottom: 23px;
    `))};

    .${classes.mobile} & {
        width: 100%;
    }
`;
const Label = styled.label`
    display: block;
    flex-shrink: 0;
    width: max-content;
    margin-right: 10px;
    color: ${palette.point.gray};
    font-size: 12px;
    white-space: nowrap;
`;
const SelectBox = styled.div`
    flex-shrink: 0;
    width: 87px;
    margin-right: 20px;
`;
const InputForm = styled.div`
    flex-grow: 1;
    height: 33px;

    .${classes.desktop} & {
        width: 353px;
        @media only screen and (max-width: ${breakPoint.sm}) {
            width: 100%;
            max-width: 353px;
        }
    }

    input {
        width: 100%;
        vertical-align: top;
        padding: 0 0 4px;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: ${palette.gray.dark};
        border-radius: 0;
        font-size: 11px;
        line-height: 14px;
    }
`;
const ErrorMessage = styled.div`
    height: 13px;
    padding-top: 2px;
    color: ${palette.point.orange};
    font-size: 9px;
`;
const Desc = styled.div`
    padding: 10px 0;
    color: ${palette.point.gray};
    .${classes.desktop} & {
        font-size: 12px;
    }
    .${classes.mobile} & {
        font-size: 10px;
    }
`;
const EditorBox = styled.div`
    .${classes.desktop} & .ck-editor__editable_inline {
        height: 190px;
    }
    .${classes.mobile} & .ck-editor__editable_inline {
        height: 150px;
    }
    ul {
        padding-inline-start: 40px;
        margin-block-start: 10px;
        margin-block-end: 10px;
        list-style-type: disc;
    }
    ol {
        padding-inline-start: 40px;
        margin-block-start: 10px;
        margin-block-end: 10px;
        list-style-type: decimal;
    }
`;
const PopupBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
`;


const FeedbackComponent = ({ type, data, errors, typeData, state, onClose, sendFeedback, platform,
    Localization = {
        'fb-feedback': 'Feedback',
        'fb-type': 'Type',
        'fb-name': 'Name',
        'fb-email': 'Email',
        'fb-desc': `Please provide detailed feedback or questions.<br />If you leave open the elements of the display about which you are leaving feedback when<br />you hit Send, you won't need to describe the page or include screen-shots.`,
        'btn-send': 'Send',
        'btn-cancel': 'Cancel',
    }
}) => {
    const [feedback, setFeedback] = useState(data);
    const handleClose = e => onClose && onClose(e);
    const textChanged = text => setFeedback(prev => ({ ...prev, feedback: text }));
    const handleTypeChange = _type => setFeedback({ ...feedback, type: _type });
    const handleNameChange = e => setFeedback({ ...feedback, name: e.target.value });
    const handleEmailChange = e => setFeedback({ ...feedback, email: e.target.value });

    useEffect(() => {
        setFeedback(data);
    }, [data]);

    return (
        <FeedbackWrapper>
            <Draggable
                handle=".handle" bounds="parent" disabled={platform === classes.mobile}
                defaultPosition={{ x:(window.innerWidth - 580) * 0.5, y:(window.innerHeight - 520) * 0.5 }}>
                <PopupWrapper>
                    <MobileDivision>
                        {!type && <CloseButton onClick={handleClose} />}
                        <PopupTitle className="handle">
                            {platform === classes.desktop && <IconDots />}
                            <strong>{Localization['fb-feedback']}</strong>
                        </PopupTitle>
                        <PopupInputs>
                            <PopupInput theme="type">
                                <Label>{Localization['fb-type']}</Label>
                                <SelectBox>
                                    <SelectForm data={typeData} onClick={handleTypeChange}>{feedback.type}</SelectForm>
                                </SelectBox>
                            </PopupInput>
                            <PopupInput>
                                <Label>{Localization['fb-name']}</Label>
                                <InputForm>
                                    <input type="text" value={feedback.name || ''} onChange={handleNameChange} />
                                    {errors && errors.name !== '' && <ErrorMessage>{errors.name}</ErrorMessage>}
                                </InputForm>
                            </PopupInput>
                        </PopupInputs>
                        <PopupInputs>
                            <PopupInput>
                                <Label>{Localization['fb-email']}</Label>
                                <InputForm>
                                    <input type="email" value={feedback.email || ''} onChange={handleEmailChange} />
                                    {errors && errors.email !== '' && <ErrorMessage>{errors.email}</ErrorMessage>}
                                </InputForm>
                            </PopupInput>
                        </PopupInputs>
                        <Desc>
                            {ReactHtmlParser(Localization['fb-desc'])}
                        </Desc>
                    </MobileDivision>

                    <MobileDivision>
                        <EditorBox>
                            <Editor text="" onTextChanged={textChanged} config={{ width: 540, height: 258, toolbar: ['bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList'] }} />
                        </EditorBox>
                        <PopupBottom>
                            <DefaultButton theme="danger" onClick={handleClose}>{Localization['btn-cancel']}</DefaultButton>
                            <DefaultButton disabled={state !== undefined && state} onClick={() => sendFeedback && sendFeedback(feedback)}>{Localization['btn-send']}</DefaultButton>
                        </PopupBottom>
                    </MobileDivision>
                </PopupWrapper>
            </Draggable>
        </FeedbackWrapper>
    );
};
export default FeedbackComponent;