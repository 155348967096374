import { os } from '@mirinae/js-utils/utils/userAgent';
import styled from 'styled-components/macro';

export const fontDefault = 'Montserrat, sans-serif';

// export const typeColors = {
//     noun: '#577AC1',
//     verb: '#C45F5B',
//     particle: '#F2A756',
// };

// following were factored out of GlobalStyling below as this was corrupting the lesson editor material-ui interface
//  all style.button's now use styled(LessonButton) to inherit common global styling for buttons
export const LessonButton = styled.button`
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    font-weight: 500;
    font-family: ${fontDefault};
    color: inherit;
    pointer-events: fill;

    svg {
        pointer-events: none;
    }

    &:disabled {
        cursor: default;
    }
`;

export const lessonsPalette = {
    patternSubtext: '#626262',
    patternSubtextLine: '#E7EDED',
    primary: {
        black: '#3C3C3B',
        white: '#FFFFFF',
        main: '#94D0CD',
    },
    grey: {
        0: '#525252',
        1: '#626262',
        2: '#7A7A7A',
        3: 'rgba(60, 60, 59, 0.5)',
        4: '#C4C4C4',
        5: '#DFDFDF',
        6:' #F4F4F4',
        7: '#FBFBFB',
    },
    light: {
        0: '#E0FBFA',
        1: '#D4E9E6',
        2: '#EFF4F7',
    },
    default: {
        0: '#A5D6D3',
        1: '#AFC3C3',
        2: '#A0C991',
        3: '#EFBAAD',
        4: '#98BEE1',
        5: '#F1D572',
        6: '#2A99CB',
    },
    darken: {
        0: '#48A9A6',
        1: '#6E9292',
        2: '#7AB365',
        3: '#F7A491',
        4: '#5D99D0',
        5: '#EAC02D',
        6: '#1C6686',
    },
    lessons: {
        0: '#F6C844',
        1: '#AF6932',
        2: '#FF8826',
        3: 'rgba(255, 136, 38, 0.15)',
        4: 'rgba(212, 233, 230, 0.4)',
    },
};
export const boxMobileSize = {
    top: '50px',
    bottom: '60px',
    innerMargin: { t: '100px', r: '10px', b: '60px', l: '10px' },
    innerPadding: { t: '40px', r: '24px', b: '100px', l: '24px' },
};
const lessonsPatternMobileSizes = {
    width: window.innerWidth - 2 * (parseInt(boxMobileSize.innerMargin.r, 10) + parseInt(boxMobileSize.innerPadding.r, 10)),
    height: window.innerHeight - parseInt(boxMobileSize.innerMargin.t, 10) - parseInt(boxMobileSize.innerMargin.b, 10)
        - parseInt(boxMobileSize.innerPadding.t, 10) - parseInt(boxMobileSize.innerPadding.b, 10)
        - parseInt(boxMobileSize.top, 10) - parseInt(boxMobileSize.bottom, 10),
};
const isPortrait = window.innerWidth < window.innerHeight; // will we force a refresh on rotation, so this is recomputed each time?
const portraitSize = { width: Math.min(window.innerHeight, window.innerWidth), height: Math.max(window.innerHeight, window.innerWidth) };
export const lessonsSizes = os.isOthers ? {
    // desktop
    lessonBounds: {
        width: 850, height: 510,
        x: 0, y: 145,
    },
    practice: {
        moveableBubble: 96,
        dragButton: { width: 116, height: 69 },
        pickLineWidth: 9,
    },
    vocabMeaningFontSize: 20,
    pattern: {
        // stage covers pattern-area, narration bubbles & control steppers
        horizontal: {
            stage: { x: 30, y: 105, width: 790, height: 380 },
            svgBox: { x: 30, y: 105, width: 790, height: 200 }, // ?
            // relative to SVG box
            patternBox: { x: 0, y: 0, width: 790 / 2, height: 200 },
            narrationBox: { x: 790 / 2, y: 0, width: 790 / 2, height: 380 },
            controls: { x: 110, y: 216, width: 175, height: 52, overflow: 'visible' },
        },
        vertical: {
            stage: { x: 30, y: 80, width: 790, height: 480 },
            svgBox: { x: 30, y: 80, width: 790, height: 480 }, // ?
            patternBox: { x: 0, y: 0, width: 790, height: 190 },
            narrationBox: { x: 100, y: 215, width: 600, height: 210 },
            controls: { x: 308, y: 205, width: 175, height: 52 },
        },
        //
        koreanFontSize: 38,
        englishFontSize: 16,
        subtextFontSize: 14,
        sectionDx: 15,
        yOrigin: 140,
        jamoBox: { fontSize: 24, width: 26, height: 32, rx: 4, fill: '#5F8887', y: -32 }, // width now computed, can be multiple jamo & arrow
        //
        // retaining for old pattern-animation versions, remove when they are fully retired
        svgBox: { x: 30, y: 105, width: 790, height: 380 },
        patternBox: { x: 0, y: 0, width: 790 / 2, height: 380 },
        narrationBox: { x: 790 / 2, y: 0, width: 790 / 2, height: 380 },
        controls: { x: 129, y: 216, width: 175, height: 52 },
    },
} : {
    // mobile
    lessonBounds: {
        width: portraitSize.width - (10 * 2), height: portraitSize.height - 100 - 60,
        x: 10, y: 100,
        margin: { left: 10, top: 100, right: 10, bottom: 20 },
        minOuterlMargin: 0, minRowMargin: 20, minTopMargin: 20,
    },
    nextButton: {
        height: 60, maxWidth: 320,
    },
    practice: {
        moveableBubble: 96,
        dragButton: { width: 116, height: 69 },
        pickLineWidth: 9,
    },
    pattern: {
        // most geometry props for mobile are computed in function below, based on a Figma layout element it is given
        koreanFontSize: 38,
        englishFontSize: 16,
        subtextFontSize: 18,
        sectionDx: 15,
        yOrigin: 120,
        jamoBox: { fontSize: 24, width: 26, height: 32, rx: 4, fill: '#5F8887', y: -32 },
    },
};

export const computeMobilePatternLayout = (element) => {
    // update pattern box & controls geometry based on given element's geom & layout
    Object.assign(lessonsSizes.pattern, {
        stage: {
            x: element.layout.row.x, y: element.layout.row.y,
            width: element.layout.row.width, height: 380,
        },
        svgBox: {
            x: 0, y: 0,
            width: element.layout.row.width,
            height: 212,
        },
        // relative to SVG box
        patternBox: {
            x: 0, y: 0,
            width: element.layout.row.width,
            height: 155,
        },
        narrationBox: {
            x: 20, y: 170,
            width:  element.layout.row.width - 40,
            height: 202,
        },
        controls: {
            x: element.layout.row.width / 2 - 175 / 2 /* 0 */, y: 380,
            width: 175 /* window.innerWidth - 60 */, height: 52,
        },
    });
};

export const colorMap = {
    noun:   'rgb(87, 122, 193)',
    verb:   '#5F8887', // 'rgb(196, 95, 91)',
    suffix: 'rgb(242, 167, 86)',
    red:    'rgb(196, 95, 91)',
    determiner: 'rgb(242, 167, 86)',
    orange: 'rgb(242, 167, 86)',
    blue:   'rgb(87, 122, 193)',
    black:  'rgb(0, 0, 0)',
    dark:   '#525252', // rgb(82, 82, 82)',
    blackish:  '#3C3C3B',
    white:  'rgb(255, 255, 255)',
    space:  'rgb(255, 255, 255)',
    highlight:  'rgb(248, 115,  19)',
    verbHighlight: 'rgb(246, 200, 68)',
    miniLesson: '#5D99D0',
    none:   'rgb(255, 255, 255)',
    target: '#3C3C3B',
    separator: 'rgb(200, 200, 200)',
    example: 'rgb(95, 136, 135)',
    wordButton: 'rgb(242, 167, 86)',
    english:  '#7a7a7a', // rgb(139, 139, 139)',
    pattern: '#F6C844', // #F87313', // rgba(246, 200, 68)', // // #F6C844
    patterSubtitle: 'rgba(175, 105, 50)', // #AF6932 retain typo for old lesson choices
    patternSubtitle: 'rgba(175, 105, 50)', // #AF6932
    deselected: '#C4C4C4',
    placeHolder: '#7d7d7d',
    addition: '#525252',
    hover: '#48A9A6',
    dim: '#7a7a7a',
    idiomSentence: '#525252',
    idiomPattern: '#6367B3',
    idiomLight: '#525252',
    '다':    '#AFC3C3', // 'rgb(175 , 195, 195)',
};

// augments the above original colorMap with optional style details, only supplied if needed
export const styleMap = {
    idiomSentence: '',
    idiomPattern: '',
    pattern: 'font-weight: bold; color: #F6C844',
    example: 'font-weight: bold; color: #5F8887',
    addition: 'font-weight: 400; color: #525252;',
    dark: 'font-weight: 400; color: #525252;',
    target: 'font-weight: 500; color: #3C3C3B;',
    englishBold: 'font-weight: 700; color: #7a7a7a;',
    idiomLight: 'font-weight: 400;',
    placeHolder: 'font-size: 85%; white-space: pre;',
    variantLight: 'font-weight: 400',
};

export const lessonsBreakPoint = {
    lg: '1200px',
    md: '988px',
    sm: '768px',
    xs: '320px',
    m: {
        laptops: '1024px',
        tablets: '768px',
        mobile: '480px',
    },
};

export const zIndex = {
    page: 2,
    float: 10,
    header: 100,
    hint: 260,
    navButtons: 250,
    tutorial: 300,
    feedbackPopup: 500,
    keyboard: 800,
    cookieBanner: 1000,
};