import React from 'react';
import styled from 'styled-components/macro';

import IconBackward from '../icons/Backward';
import IconArrowLeft from '../icons/ArrowLeft';
import { palette } from '../../defines/styles';

const Button = styled.button`
    display: flex;
    align-items: center;
    height: ${(props) => (props.theme === 'step' ? '32px' : '20px')};
    font-size: ${(props) => (props.size === 'small' ? '13px' : '12px')};
    text-transform: ${(props) =>
        props.size === 'small' ? 'lowerCase' : 'capitalize'};
    padding: 0;
    border: 0;
    background: transparent;

    &:disabled {
        color: #DFDFDF;
        svg {
            filter: grayscale(1);
        }
    }

    &:hover:not(:disabled) {
        svg {
            transform: rotateX(180deg);
        }
    }

    svg {
        margin-right: 10px;
        ${(props) =>
            props.theme === 'step' && `
            margin-top: 1px;
        `}
    }
`;

const BackButton = props => (
    <Button
        type="button"
        name={props.name || 'back'}
        {...props}
    >
        {props.size === 'small' ? (
            <IconArrowLeft width="9" height="15" />
        ) : (
            <IconBackward width="20" height="20" />
        )}
        {props.children}
    </Button>
);

export default BackButton;
