import React from 'react';
import styled from 'styled-components/macro';

import IconCheck from '../icons/Check';
import { palette } from '../../defines/styles';

const Button = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: transparent;
    svg {
        vertical-align: middle;
        g {
            fill: ${palette.point.red};
        }
    }
    &:hover {
        svg g {
            fill: ${palette.point.red};
        }
    }
`;

const CheckButton = props => (
    <Button type="button" onClick={e => props.onClick && props.onClick(e)}>
        <IconCheck width="12" height="10" />
    </Button>
);

export default CheckButton;