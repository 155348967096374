import React, { useState, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';

import { palette, zIndex } from '@mirinae/react-ui';
import TryFreeLink from '@mirinae/explorer/components/buttons/TryFreeButton';
import { ReactComponent as IconRocket } from '@mirinae/explorer/components/assets/icon-rocket.svg';
import { ReactComponent as PadLock } from '@mirinae/explorer/components/assets/padlock.svg';
import { ReactComponent as IconVideo } from '@mirinae/explorer/components/assets/icon-video.svg';
import { ReactComponent as IconPlay } from '@mirinae/explorer/components/assets/icon-play.svg';
import { ReactComponent as IconDisabledVideo } from '@mirinae/explorer/components/assets/disabled-video.svg';
import Localization from '@mirinae/shared/il8n';
import { ExternalLinks, membersPaths, navigateExternal } from '@mirinae/shared/defines/paths';
import { buildExternalURL } from '@mirinae/js-utils';
import CloseButton from '@mirinae/explorer/components/exploration/input/ClearButton';
import VerifyEmailReminder from '@mirinae/explorer/components/common/VerifyEmailReminder';
import { getUserType, getTrialStatus } from '@mirinae/shared/defines/datas';
import { useProAccessStore, useProAccessStoreAndMethods } from '@mirinae/stores/proAccessStore';
import { useAppVersionStore } from '@mirinae/stores/appVersion';
import { os } from '@mirinae/js-utils/utils/userAgent';
import { UserContext } from '@mirinae/react-auth';

export const Popup = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${zIndex.cookieBanner + 10};
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
`;
export const PopupInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    padding: 40px;
    border-radius: 10px;
    background-color: ${palette.primary.white};
    text-align: center;
    a {
        width: 100%;
    }
    svg {
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 390px) {
        width: 100%;
        margin: 0 20px;
        padding: 40px 20px;
    }
`;
export const Title = styled.h4`
    margin: 10px 20px 10px;
    color: ${palette.gray[1]};
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
`;
export const Text = styled.div`
    margin: 0 20px 20px;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;

export const AdButton = styled.button`
    display: flex;
    width: 100%;
    min-height: 56px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;    
    border-radius: 10px;
    border: 1px solid var(--gray-gray-06, #DFDFDF);
    background: var(--primary-white, #FFF);    
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.10);
    gap: 10px;
  
    color: var(--gray-gray-01, #525252);
    font-size: 14px;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
  
    svg {
        margin: 0;
        pointer-events: auto;
    }
  
    & span {
        font-size: 14px;
        font-weight: 400;
    }
`;

export const PlayAd = styled(AdButton)`
    margin-bottom: 20px;
`;

export const MorePlaysTomorrow = styled(AdButton)`
    margin-top: 20px;
    color: var(--gray-gray-05, #C4C4C4);
    box-shadow: none;
    cursor: default;
`;

export const GetLatestApp = styled(AdButton)`
    margin-top: 20px;
    color: #8A8A8A;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.5px; /* 132.143% */
    gap: 7px;
    padding: 10px;
`;

const CancelButton = styled.button`
    display: block;
    width: 100%;
    height: 25px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(to right, #3499FF 0%, #3A3985 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const LearnMoreLink = styled.a`
    display: block;
    width: 100%;
    height: 25px;
    line-height: 25px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(to right, #3499FF 0%, #3A3985 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const UpgradeProPopup = ({ }) => {
    const [userContext, ] = useContext(UserContext);
    const { user } = userContext;
    const accessKey = useProAccessStore(state => state.accessKey);
    const [displayUpgradeToPro, { playRewardedAd, closeUpgradePopup }] = useProAccessStoreAndMethods(state => state.displayUpgradeToPro);
    const [showPopupMode, setShowPopupMode] = useState('');
    const { isApp, versionParam, isProEnabledVersion, isAdMobAdsEnabled } = useAppVersionStore();

    const userType = getUserType(user);
    const trialStatus = getTrialStatus(user);
    const awaitingEmailVerification = (user?.role === 'guest' && user?.loginType === 'local') || user?.status === 'awaiting-verification';

    useEffect(() => {
        if (displayUpgradeToPro && accessKey) {
            // set up upgrade popup messages...
            setShowPopupMode('show');
        } else {
            setShowPopupMode('');
        }
    }, [displayUpgradeToPro, accessKey]);

    const adsLeftToday = accessKey ? accessKey.limitCount - accessKey.dayCount - 1 : 0;

    return showPopupMode ? (
        awaitingEmailVerification ? (
            <VerifyEmailReminder email={user.email} close={closeUpgradePopup} />
        ) : (
            <Popup>
                <PopupInner>
                    { accessKey.available && isAdMobAdsEnabled ? (
                        <>
                            <IconVideo />
                            <Title>{Localization['propopup-title-4']}</Title>
                            <Text>{Localization['propopup-text-5']}</Text>
                            <PlayAd onClick={() => playRewardedAd(user, accessKey)}>
                                <IconPlay />
                                {Localization['propopup-playad']}
                                {accessKey.limitCount && <span>{adsLeftToday === 0 ? Localization['propopup-lastonetoday'] : Localization['propopup-moretoday'].replace('1', adsLeftToday)}</span>}
                            </PlayAd>
                        </>
                    ) : (
                        <>
                            { userType === 'free' ? <IconRocket /> : <PadLock /> }
                            <Title>{Localization['propopup-title-3']}</Title>
                            <Text>{Localization['propopup-text-3']}</Text>
                        </>
                    )}
                    <TryFreeLink userType={userType} trialStatus={trialStatus} />
                    { isAdMobAdsEnabled ? (
                        ( accessKey.limitExceeded && (
                            <MorePlaysTomorrow>
                                <IconDisabledVideo />
                                {Localization[`propopup-moreplays-${accessKey.type}`]}
                            </MorePlaysTomorrow>
                        ))
                    ) : (
                        <GetLatestApp onClick={() => navigateExternal(os.isIos || os.isMac ? ExternalLinks.app.apple : ExternalLinks.app.playStore)}>
                            <IconVideo />
                            {Localization[isApp ? 'propopup-upgrade-app' : 'propopup-get-app']}
                        </GetLatestApp>
                    )}
                    { isProEnabledVersion && (
                        <LearnMoreLink href={buildExternalURL(membersPaths.pro, versionParam)}>{Localization['learn-more']}</LearnMoreLink>
                    )}
                    <CloseButton className="btn-cancel" onClick={closeUpgradePopup} />
                </PopupInner>
            </Popup>
        )
    ) : null;
};

export default UpgradeProPopup;