import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { palette } from '../../defines/styles';

const ButtonToggleWrapper = styled.div`
    display: flex;
    font-size: 13px;
    font-weight: 500;
    ${props => (!props.disabled && 'cursor: pointer')};
`;
const ToggleIcon = styled.span`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 22px;
    height: 12px;
    padding: 2px;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 6px;
    background-color: ${props => (props.disabled ? palette.gray.light : props.status ? props.theme === 'green' ? palette.point.green : palette.point.blue : '#565656')};

    &:after {
        content: '';
        display: block;
        position: relative;
        left: ${props => (props.status ? '10px' : '0')};
        transition: all 0.1s ease-out;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: ${palette.white};
    }
`;

const ToggleButton = props => {
    const [state, setState] = useState(props.state ? props.state : false);

    return (
        <ButtonToggleWrapper
            disabled={props.disabled}
            className="toggle-button"
            onClick={() => {
                if (props.disabled) return;
                setState(!state);
                if (props.onChange) props.onChange(!state);
            }}
        >
            <ToggleIcon status={state} disabled={props.disabled} theme={props.theme} />
            {props.children}
        </ButtonToggleWrapper>
    );
};

export default ToggleButton;