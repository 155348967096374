import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { produce, current } from 'immer';
import { getProOrStaff } from '@mirinae/defines/datas';
import { httpAPI } from '@mirinae/react-auth';
import { apiPaths, authPaths, explorerPaths, navigateExternal } from '@mirinae/defines/paths';
import { buildExternalURL } from '@mirinae/js-utils/utils/links';
import { userInit } from '@mirinae/react-auth/modules/apis/auth';

export const featureFor = (f) => `${f.type}|${f.objectID || ''}|${f.entityID || ''}`;

// global control of upgrade-to-pro popups
export const useProAccessStore = create((set, get) => ({
    displayUpgradeToPro: false,
    displayMustUpgradeApp: false,
    accessKey: null,
    rewardedCallback: null,
    accessKeys: { },

    methods: {
        displayUpgradeToProPopup: (accessKey = { }, rewardedCallback) => set({ displayUpgradeToPro: true, accessKey, rewardedCallback }),
        displayMustUpgradeAppPopup: () => set({ displayMustUpgradeApp: true }),
        closeUpgradePopup: () => set({ displayUpgradeToPro: false, displayMustUpgradeApp: false, accessKey: null, rewardedCallback: null }),

        setAccessKey: (feature, key, rewardedCallback) => set(produce(state => { state.accessKeys[feature] = key; state.rewardedCallback = rewardedCallback; })),
        clearAccessKeys: () => set({ accessKeys: { } }),

        // check if user has access to the listed features via the access Key system
        accessProFeatures: (features, user,) => new Promise((resolve, reject) => {
            if (user && user.role !== 'guest' && !getProOrStaff(user)) {
                if (true) {  // for testing, should check isApp since  AdMob reward ads only for apps for us just now; NO, we allow desktop users to access rewards earnt on user's apps
                    // gather any new individual features
                    const newFeatures = [];
                    features.forEach(f => set(produce(state => {
                        const feature = featureFor(f);
                        if (f.url) f.url = buildExternalURL(f.url);
                        if (!state.accessKeys[feature]) {
                            state.accessKeys[feature] = f;
                            if (state.accessKeys[f.type]) {
                                Object.assign(f, current(state.accessKeys)[f.type]);
                            }
                            newFeatures.push(f);
                        }
                        state.accessKeys[feature].feature = feature;
                    })));
                    // only test access if feature type not in accessKeys already
                    const types = new Set(features.map(f => f.type).filter(t => !(get().accessKeys[t])));
                    if (types.size > 0) {
                        httpAPI('', apiPaths.accessFeatures, { data: { types: Array.from(types) } })
                            .then(response => set(produce(state => {
                                const { keys } = response.data;
                                console.log('apiPaths.accessFeatures', JSON.stringify(keys))
                                newFeatures.forEach(f => {
                                    if (keys[f.type]) {
                                        for (const [k, v] of Object.entries(keys[f.type])) {
                                            state.accessKeys[f.feature][k] = v;
                                        }
                                    }
                                })
                                for (const [k, v] of Object.entries(keys)) {
                                    state.accessKeys[k] = v;
                                }
                                resolve(features.map(f => current(state.accessKeys)[featureFor(f)]));
                            }))).catch(e => {
                                console.log(e);
                            });
                    } else {
                        resolve(features.map(f => get().accessKeys[featureFor(f)]));
                    }
                }
            }
        }),

        // ask flutter to play the ad unit associtaed with given accessKey & unlock feature when played
        playRewardedAd: (user, accessKey, rewardedCallback) => {
            const callback = rewardedCallback || get().rewardedCallback;
            const { type, feature, token, url } = accessKey || get().accessKey;
            get().methods.closeUpgradePopup();
            if (user.role === 'guest') {
                if (window.ProfileChannel) {
                    window.ProfileChannel.postMessage(JSON.stringify({ command: 'signOut', value: true })); // webview
                } else if (window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler('ProfileChannel', { command: 'signOut', value: true });
                } else {
                    navigateExternal(`${authPaths.signup}?redirect=${explorerPaths.home}`);
                }
            } else {
                const adShown = ({ adUnitId, rewarded, viewed }) => {
                    // got a reward, grant access
                    console.log('adShown', adUnitId, rewarded, viewed);
                    if (rewarded || viewed) {
                        const data = { type, feature, token };
                        httpAPI('', apiPaths.grantKey, { data })
                            .then(response => {
                                const { key, error } = response.data;
                                if (key) {
                                    console.log('got reward access key', JSON.stringify(key), 'callback', callback, 'url', url)
                                    set(produce(state => { state.accessKeys[key.feature] = key; }));
                                    if (callback)
                                        callback(key);
                                    else if (url)
                                        navigateExternal(url)
                                    else
                                        window.location.reload();
                                } else {
                                    // show failure reason!
                                }
                            });
                    }
                };

                if (window.flutter_inappwebview) {
                    console.log('calling ShowAd', JSON.stringify(accessKey))
                    // if both types of ad present, will show RewardedAd
                    const type = accessKey.adUnits.RewardedAd ? 'RewardedAd' : 'InterstitialAd';
                    window.flutter_inappwebview.callHandler('ShowAd', { type, unitId: accessKey.adUnits[type] })
                        .then(adShown);
                } else {
                    // for desktop testing
                    adShown({ });
                }
            }
        },

        // check if user has access to requested feature, either because pro or in users accessKeys or current store's accessKeys
        hasAccess: (user, feature) => {
            if (user) {
                const accessKeys = (user.accessKeys || []).concat(Object.values(get().accessKeys));
                return getProOrStaff(user) ||
                    accessKeys?.some(ak =>
                        ak.feature === feature && ak.status === 'unlocked' &&
                        (!ak.expiry || new Date(ak.expiry).getTime() > Date.now()));
            }
        },
    },
}));

export const useProAccessStoreMethods = () =>
    useProAccessStore((state) => state.methods, shallow);
export const useProAccessStoreAndMethods = (stateGetter, flags) => [
    useProAccessStore(stateGetter, flags),
    useProAccessStore((state) => state.methods, shallow),
];

