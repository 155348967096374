import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { UserAgent } from '@mirinae/js-utils';
import GTM from '@mirinae/apis/GoogleAnalytics';

import AppBase from '@mirinae/shared/components/appBase';
import { redirectHashURLs } from '@mirinae/defines/paths';
import Explorer from './Explorer';

const { os, browser } = UserAgent;

const root = ReactDOM.createRoot(document.getElementById('root'));

// detect attempts to open mirinae.io inside in-app browsers (such as in Facebook, etc.) and force them to open in the device's main mobile browser
if (os.isAndroid && browser.isInApp) {
    window.location.href = `intent://${window.location.href.replace(window.location.protocol, '').replace('//', '')}#Intent;scheme=https;package=com.android.chrome;end`;
} else if (os.isIos && browser.isInApp) {
    window.location.href = `googlechrome://${window.location.href.replace(window.location.protocol, '').replace('//', '')}`;
} else {
    // the following hoops are all related to reliably-determing if we are in a Flutter in-app webview, and if so, getting the app's version number.
    // this has to be done before any of the component hydration code runs, but has to be via a cascade of async events, waited on with short timeouts
    // the various paths through all this eventually call the following mountMirinae() function, which does the actual react component tree mounting.

    const mountMirinae = () => {
        GTM.initialize(); // initialize GTM tag container, must be done prior to the ReactDOM.render() call

        root.render(
            <React.StrictMode>
                <CookiesProvider>
                    <BrowserRouter>
                        <AppBase>
                            <Routes>
                                {/* need this to properly resolve paths when mounting app subpaths */}
                                <Route path="explorer/*" element={<Explorer />} />
                                <Route path="*" element={redirectHashURLs(window.inAppView /* isApp */, <Explorer />)} />
                            </Routes>
                        </AppBase>
                    </BrowserRouter>
                </CookiesProvider>
            </React.StrictMode>
        );
    }

    if (!os.isOthers) {
        // only bother with this if we are running on a mobile device (in one of our apps or in a mobile browser)
        // for now, anti-pattern that it is, I am dropping the initial discovery results in some windows variables
        // default to not in an app, and even if so, version 0 if it sends us not appVersion message
        window.inAppView = false;
        window.appVersion = 0;

        // wait a bit for flutter's built-in InAppWebView ready and app-version messages, only if on a mobile device OS
        // the timed-out wait is via a Promise.race() on a timeout & event promise
        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (typeof window.flutter_inappwebview !== 'undefined') {
                    // may have missed the flutterInAppWebViewPlatformReady event, but by now the window.flutter_inappwebview should be present, resolve if so
                    resolve();
                } else {
                    reject();
                }
            }, 250, 'Timed out'); // 250ms for now, is this right???
        });

        const eventPromise = new Promise(resolve => {
            window.addEventListener('flutterInAppWebViewPlatformReady', resolve);
        });

        Promise.race([eventPromise, timeoutPromise])
            .then(() => {
                // got the flutter ready message, now wait in the same way for the appVersion message our flutter code posts
                window.inAppView = true;
                // flutterInAppWebViewPlatformReady fired, wait for appVersion message
                const timeoutPromise = new Promise((resolve, reject) => {
                    setTimeout(reject, 250, 'Timed out'); // another 250ms for now
                });

                const eventPromise = new Promise(resolve => {
                    window.addEventListener('message', e => {
                        if (e.data?.appVersion) {
                            resolve(e);  // probably should unregister this handler!!
                        }
                    });
                });

                Promise.race([eventPromise, timeoutPromise])
                    .then(e => {
                        // appVersion message received, grab the app version
                        if (e.data.appVersion) {
                            window.appVersion = e.data.appVersion;
                            console.log('got appVersion', window.appVersion);
                        }
                        mountMirinae();
                    })
                    .catch(err => {
                        // appVersion timed out
                        mountMirinae();
                    });
            })
            .catch(err => {
                // flutterInAppWebViewPlatformReady timed out
                mountMirinae();
            });
    } else {
        mountMirinae();
    }
}
