import React from 'react';
import styled from 'styled-components/macro';
import ReactHtmlParser from 'html-react-parser';

import { palette, breakPoint, zIndex } from '@mirinae/react-ui';

import Localization from '@mirinae/shared/il8n/index';
import IconCookieHead from '@mirinae/explorer/components/assets/icon-cookie-head.svg';
import { ReactComponent as IconCloseX } from '@mirinae/explorer/components/assets/icon-close-light.svg';

const PopUpWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    //right: 20px;
    //bottom: 20px;
    z-index: ${zIndex.cookieBanner};

    @media only screen and (max-width: ${breakPoint.xs}) {
        right: initial;
    }
`;
const PopUpContent = styled.div`
    position: fixed;
    bottom: 20px;
    right: 45px;
    max-width: 347px;
    min-width: 280px;
    height: fit-content;
    min-height: 42px;
    padding-left: 54px;
    background: ${palette.primary.white} url(${IconCookieHead}) no-repeat 20px 50%;
    padding: 15px 40px 15px 60px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border: 1px solid ${palette.gray[7]};
    border-radius: 10px;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        right: revert;
        left: 20px;
        bottom: 120px;
    }

    a {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;

        :hover {
            color: ${palette.main.darken[2]};
        }
    }

    button {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        :hover {
            path {
                stroke: ${palette.gray[2]};
            }
        }
    }
`;

const CookiePopUp = (props) => (
    <PopUpWrapper>
        <PopUpContent>
            {ReactHtmlParser(
                Localization.formatString(Localization['ck-text'], props.link),
            )}
            <button type="button" onClick={e => props.onClick && props.onClick(e)}>
                <IconCloseX />
            </button>
        </PopUpContent>
    </PopUpWrapper>
);

export default CookiePopUp;
