// CHANGE THE VALUE HERE TO CHOOSE EITHER SANDBOX TEST OR PAYPAL PRODUCTION
export const isSandboxTest = window.location.host.match(/^alpha\./) !== null || process.env.REACT_APP_MODE === 'LOCAL';

export const currentPricePlanVersion = '4.0'; // Hangul-day promotion until October 31st 2023; restore to 1.0 for regular pricing; // current price-plan version
export const currentTrialDays = 7;

export const getUserType = (user) => {
    if (user) {
        if (!user.role || user.role === 'guest') return 'guest';
        if (user?.accountData?.purchases?.explorer_subscription?.status) {
            const { status } = user.accountData.purchases.explorer_subscription;
            return (status !== 'expired' && status !== 'trial-expired') ? 'pro' : 'free'; // if status is not expired, the user must be Pro
        }
        return 'free'; // return values - guest | free | pro
    } return 'guest';
};

export const getProOrStaff = (user) => getUserType(user) === 'pro' || (user?.role?.match(/admin|super|lesson/));

export const getUserStatus = (user) => {
    if (user) {
        if (!user.role || user.loginType === 'guest') return 'guest';
        if (user?.accountData?.purchases?.explorer_subscription?.status) {
            const { status } = user.accountData.purchases.explorer_subscription;
            return status;
        }
        return 'free'; // return values - guest | free | pro
    } return 'guest';
};

export const getTrialStatus = (user) => {
    if (user) {
        if (!user.role || user.loginType === 'guest') return 'no-trial';

        if  (user?.accountData?.purchases?.explorer_subscription?.status) {
            const { status } = user.accountData.purchases.explorer_subscription;
            switch (status) {
                case 'trial-signup':
                case 'trial-cc':
                case 'trial':
                    return 'in-trial';
                default:
                    return 'had-trial';
            }
        } else {
            return getProOrStaff(user) ? 'had-trial' : 'no-trial';
        }
    } return '';
};

export const getPlanNameFromPlanType = (user) => {
    if (getUserType(user) === 'pro') {
        if (user?.accountData?.purchases?.explorer_subscription?.planType) {
            switch (user.accountData.purchases.explorer_subscription.planType) {
            case '1m': return 'Monthly Plan';
            case '3m': return 'Quarterly Plan';
            case '12m': return 'Annual Plan';
            default: return 'no valid plan';
            }
        } else {
            return 'no valid plan';
        }
    }
};

const sandboxPlanIds = {
    '4.0': { // Apr'24, renable CC-based trials
        with_trial: {
            annual: 'P-08438440JE2688447MYGPLZA',
            monthly: 'P-4LK27347DC930570BMYGPLAY',
        },
        without_trial: {
            annual: 'P-07F4502492753702SMYGPODI',
            monthly: 'P-34K48760WD658874WMYGPLMA',
        },
    },
    '3.0': { // Nov'23, renable CC-based trials
        with_trial: {
            annual: 'P-6V320016T4719553JMU5CIPQ',
            monthly: 'P-37B219379C4423534MU5CIZI',
        },
        without_trial: {
            annual: 'P-1A4163983N332533UMU5CFMQ',
            monthly: 'P-99D14879128106206MU5CIBQ',
        },
    },
    '2.0': {
        with_trial: {
            annual: 'P-2CF73678MC1600614MRC6IVI',
            monthly: 'P-0X818633B4281225XMRC6BMA',
        },
        without_trial: {
            annual: 'P-8FJ86035SM946220RMRC6QGA',
            monthly: 'P-1MH96891U4562924MMRC6RCQ',
        },
    },
    '1.0': {
        with_trial:{
            annual : 'P-0RU38310E2088402WMNRRSGQ',
            quarterly: 'P-37G434131R352235JMNRRR6Y',
            monthly: 'P-72096727DB019683NMNRRRUY',
        },
        without_trial:{
            annual : 'P-9A422205WA5787431MNRRQWA',
            quarterly: 'P-1PP38726U0507583UMNRRQMA',
            monthly: 'P-13S1982625175574HMNRRP3Q',
        },
    },
};

const paypalPlanIds = {
    '4.0': { // Apr'24, renable CC-based trials
        with_trial: {
            annual: 'P-1L944411FX5800134MYFGXFY',
            monthly: 'P-8YD81478N1323614CMYFGU2I',
        },
        without_trial: {
            annual: 'P-7TD0869945155071XMYGPMWI',
            monthly: 'P-32A46426UL3337312MYFGVMY',
        },
    },
    '3.0': { // Nov'23, renable CC-based trials
        with_trial: {
            annual: 'P-0WH34447MM2483317MU5BX2A',
            monthly: 'P-4RP56887KH552152TMU5BW3I',
        },
        without_trial: {
            annual: 'P-32Y776446V182282LMU5BYUY',
            monthly: 'P-07E79367L7150973MMU5BYGA',
        },
    },
    '2.0': {
        with_trial: {
            annual: 'P-7HH51319X9757202HMQ7CGNI',
            monthly: 'P-6SS99955W2215733PMQ7CIBI',
        },
        without_trial: {
            annual: 'P-8CM63787VM3035257MRC6RZQ',
            monthly: 'P-7U351286DN6818909MRC6SNQ',
        },
    },
    '1.0': {
        with_trial:{
            annual : 'P-93205845HH202835XMNSKN6Y',
            quarterly: 'P-67512659KB8718408MNSKNJI',
            monthly: 'P-2BT609163V771743VMNSKMIQ',
        },
        without_trial:{
            annual: 'P-7CR79992JL892871AMNSKP3Q',
            quarterly: 'P-0BV065308J803812BMNSKPLQ',
            monthly : 'P-5JV52393FN0078241MNSKO3I',
        },
    },
};

const planIds = isSandboxTest ? sandboxPlanIds : paypalPlanIds;

export const pricesData = {
    '4.0': { // Nov'23, back to the C-based-trial and signup flow, modified prices for annual & monthly only
        with_trial:{
            annual : {
                title: 'Annual Plan', how: 'billed annually',
                originPrice: 89.98, monthlyPrice: 3.75, totalPrice: 44.99, discount: 50,
                label: 'SAVE 50%', unit: '$', period: 'year',
                planId: planIds['4.0'].with_trial.annual,
                months: '12', trialDays: '7', paymentPeriod: 'annually',
            },
            monthly: {
                title: 'Monthly Plan', how: 'billed monthly',
                originPrice: 11.99, monthlyPrice: 11.99, totalPrice: 11.99,
                label: '', unit: '$', period: 'month',
                planId: planIds['4.0'].with_trial.monthly,
                months: '1', trialDays: '7', paymentPeriod: 'monthly',
            },
        },
        without_trial:{
            annual : {
                title: 'Annual Plan', how: 'billed today', discount: 50,
                originPrice: 89.98, monthlyPrice: 3.75, totalPrice: 44.99,
                label: 'SAVE 50%', unit: '$', period: 'month',
                planId: planIds['4.0'].without_trial.annual,
                months: '12', trialDays: '', paymentPeriod: 'annually',
            },
            monthly: {
                title: 'Monthly Plan', how: 'billed today',
                originPrice: 11.99, monthlyPrice: 11.99, totalPrice: 11.99,
                label: 'SAVE 50%', unit: '$', period: 'month',
                planId: planIds['4.0'].without_trial.monthly,
                months: '1', trialDays: '', paymentPeriod: 'monthly',
            },
        },
    },
    '3.0': { // Nov'23, back to the C-based-trial and signup flow, modified prices for annual & monthly only
        with_trial:{
            annual : {
                title: 'Annual Plan', how: 'billed annually',
                originPrice: 143.88, monthlyPrice: 5.99, totalPrice: 71.99, discount: 50,
                label: 'SAVE 50%', unit: '$', period: 'year',
                planId: planIds['3.0'].with_trial.annual,
                months: '12', trialDays: '7', paymentPeriod: 'annually',
            },
            monthly: {
                title: 'Monthly Plan', how: 'billed monthly',
                originPrice: 11.99, monthlyPrice: 11.99, totalPrice: 11.99,
                label: '', unit: '$', period: 'month',
                planId: planIds['3.0'].with_trial.monthly,
                months: '1', trialDays: '7', paymentPeriod: 'monthly',
            },
        },
        without_trial:{
            annual : {
                title: 'Annual Plan', how: 'billed today', discount: 50,
                originPrice: 143.88, monthlyPrice: 5.99, totalPrice: 71.99,
                label: 'SAVE 50%', unit: '$', period: 'month',
                planId: planIds['3.0'].without_trial.annual,
                months: '12', trialDays: '', paymentPeriod: 'annually',
            },
            monthly: {
                title: 'Monthly Plan', how: 'billed today',
                originPrice: 11.99, monthlyPrice: 11.99, totalPrice: 11.99,
                label: 'SAVE 50%', unit: '$', period: 'month',
                planId: planIds['3.0'].without_trial.monthly,
                months: '1', trialDays: '', paymentPeriod: 'monthly',
            },
        },
    },
    '2.0': { // taken for the Hangul Day promo pricing!!  @50% discount
        annual : {
            title: 'Annually', desc: '<strong>41.99</strong> USD billed today',
            originPrice: 83.88, monthlyPrice: '3.50', totalPrice: 41.99, discount: 50,
            label: 'Get 2 months more', unit: '$', period: 'year',
            planId: planIds['2.0'].without_trial.annual,
            months: '12', trialDays: '',
        },
        monthly: {
            title: 'Monthly', desc: '<strong>$6.99</strong> USD billed today',
            originPrice: 11.99, monthlyPrice: 6.99, totalPrice: 6.99,
            unit: '$', period: 'month',
            planId: planIds['1.0'].without_trial.monthly,
            months: '1', trialDays: '',
        },
    },
    '2.0-45%': { // taken for the Hangul Day promo pricing!!
        annual : {
            title: 'Annually', desc: '<strong>45.99</strong> USD billed today',
            originPrice: 83.88, monthlyPrice: 3.83, totalPrice: 45.99, discount: 45,
            label: 'Get 2 months more', unit: '$', period: 'year',
            planId: planIds['2.0'].without_trial.annual,
            months: '12', trialDays: '',
        },
        monthly: {
            title: 'Monthly', desc: '<strong>$6.99</strong> USD billed today',
            originPrice: 11.99, monthlyPrice: 6.99, totalPrice: 6.99,
            unit: '$', period: 'month',
            planId: planIds['1.0'].without_trial.monthly,
            months: '1', trialDays: '',
        },
    },
    '2.5': { // original plan 2.0 with increased pricing
        annual : {
            title: 'Annually', desc: '<strong>$99</strong> USD billed today',
            originPrice: 120, monthlyPrice: 6.67, totalPrice: 79.99, discount: 33,
            label: 'Get 2months more', unit: '$', period: 'year',
            planId: planIds['2.0'].without_trial.annual,
            months: '12', trialDays: '',
        },
        monthly: {
            title: 'Monthly', desc: '<strong>$9.99</strong> USD billed today',
            // originPrice: 9.99,
            monthlyPrice: 9.99, totalPrice: 9.99,
            // label: 'SAVE 00%',
            unit: '$', period: 'month',
            planId: planIds['2.0'].without_trial.monthly,
            months: '1', trialDays: '',
        },
    },
    '1.0' : {
        // with_trial:{
        //     annual : {
        //         title: 'Annually', desc: '<strong>$47.99</strong> USD billed after trial',
        //         originPrice: 11.99, monthlyPrice: 3.99, totalPrice: 47.99,
        //         label: 'SAVE 00%', unit: '$', period: 'month',
        //         planId: planIds['1.0'].with_trial.annual,
        //         months: '12', trialDays: '14',
        //     },
        //     quarterly: {
        //         title: 'Quarterly', desc: '<strong>$14.99</strong> USD billed after trial',
        //         originPrice: 11.99, monthlyPrice: 4.99, totalPrice: 14.99,
        //         label: 'SAVE 00%', unit: '$', period: 'month',
        //         planId: planIds['1.0'].with_trial.quarterly,    '2.0': {
        //         annual : {
        //             title: 'Annual', desc: '<strong>$99</strong> USD billed today',
        //             originPrice: 120, monthlyPrice: 6.67, totalPrice: 79.99, discount: 33,
        //             label: 'Get 2months more', unit: '$', period: 'year',
        //             planId: planIds['2.0'].without_trial.annual,
        //             months: '12', trialDays: '',
        //         },
        //         monthly: {
        //             title: 'Monthly', desc: '<strong>$9.99</strong> USD billed today',
        //             // originPrice: 9.99,
        //             monthlyPrice: 9.99, totalPrice: 9.99,
        //             // label: 'SAVE 00%',
        //             unit: '$', period: 'month',
        //             planId: planIds['2.0'].without_trial.monthly,
        //             months: '1', trialDays: '',
        //         },
        //     },
        //         months: '3', trialDays: '14',
        //     },
        //     monthly: {
        //         title: 'Monthly', desc: '<strong>$6.99</strong> USD billed after trial',
        //         originPrice: 11.99, monthlyPrice: 6.99, totalPrice: 6.99,
        //         label: 'SAVE 00%', unit: '$', period: 'month',
        //         planId: planIds['1.0'].with_trial.monthly,
        //         months: '1', trialDays: '14',
        //     },
        // },

        // without_trial:{     removed for now, since we no longer offer the trial/without-trial option on paid subscription start
        annual : {
            title: 'Annually', desc: '<strong>$47.99</strong> USD billed today',
            originPrice: 83.88, monthlyPrice: 3.99, totalPrice: 47.99, discount: 43,
            unit: '$', period: 'year',
            planId: planIds['1.0'].without_trial.annual,
            months: '12', trialDays: '',
        },
        quarterly: {
            title: 'Quarterly', desc: '<strong>$14.99</strong> USD billed today',
            originPrice: 11.99, monthlyPrice: 4.99, totalPrice: 14.99,
            unit: '$', period: 'month',
            planId: planIds['1.0'].without_trial.quarterly,
            months: '3', trialDays: '',
        },
        monthly: {
            title: 'Monthly', desc: '<strong>$6.99</strong> USD billed today',
            originPrice: 11.99, monthlyPrice: 6.99, totalPrice: 6.99,
            unit: '$', period: 'month',
            planId: planIds['1.0'].without_trial.monthly,
            months: '1', trialDays: '',
        },
    },
};

export const promotionPriceData = {
    youtube_promo:{
        title: 'Monthly', desc: '<strong>$0</strong> USD billed today!',
        originPrice: 11.99, monthlyPrice: 6.99, totalPrice: 6.99,
        label: 'SAVE 41%', unit: '$', period: 'month',
        planId: 'P-8L438235AE909315AMOCEELA',
        months: '1', trialDays: '30',
    },
};

export const plansData = {
    guest: {
        title: 'guest',
        status: 'Without registering',
        text: 'Explore Mirinae without setting up an account. You can sign up for a free account later.',
    },
    free: {
        title: 'free',
        status: 'Core features',
        text: 'Create a free account and get access Mirinae’s core features.',
    },
    pro: {
        title: 'pro',
        status: 'No limits!',
        text: 'Use all our features without restriction.  Power up your learning with the best Korean study tools available!',
    },
};

export const featuresData = {
    'Exploratory Learning': {
        'Exploration History': { guest: 'Limited', free: true, pro: true },
        'Sentence Explanations': { guest: 'Limited', free: 'Limited', pro: true },
        'Pronunciation help': { guest: false, free: 'Limited', pro: true },
    },
    'Additional Exploration modes': {
        'Image and camera Input': { guest: 'Limited', free: true, pro: true },
        'Browsing other sites': { guest: false, free: 'Limited', pro: true },
        'Streaming Video Subtitles': { guest: false, free: false, pro: true },
    },
    'Dive Deeper': {
        'Related Discussions': { guest: 'View only', free: true, pro: true },
        'Related Lessons': { guest: false, free: 'Limited', pro: true },
        'Similar Examples': { guest: false, free: false, pro: true },
        'Related grammar': { guest: false, free: false, pro: true },
        'External References': { guest: false, free: false, pro: true },
    },
    'Structured Learning': {
        'Mirinae Lessons (English only for now)': { guest: false, free: 'Limited', pro: true },
    },
    'Social Learning': {
        'Discussion boards': { guest: 'View only', free: true, pro: true },
    },
    'Reference Library': {
        'Glossary of terms': { guest: true, free: true, pro: true },
        'Grammar and idiom reference': { guest: 'Limited', free: 'Limited', pro: true },
    },
};

export const paypalCredentials = {
    SANBOX_CLIENTID: 'ATJCy6fSrsAK0nYed4kBzS5F6Y5MpXcmi21h9HpCOAcPpfV4fIr9X1eFQVJg-qAPoJJgvk_e1Qs66i1B',
    PAYPAL_CLIENTID: 'AYVgusbBFzJhC-08CTI3l5xvpMD8ZK-zo1iMS-uAce5BRKVV6micgViPQysGOMYtKE4Cb4NkGghUbJLQ',
};

export const promotionSessionKey = {
    'YT-DEC2022': 'YT-DEC2022',
};