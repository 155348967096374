/* eslint-disable no-lonely-if */
import React from 'react';
import styled from 'styled-components/macro';

import { breakPoint, classes, size } from '../defines/styles';
// import MoveTopButton from '../components/buttons/MoveTopButton';
// import { UserAgent } from '@mirinae/js-utils';

// const animteScrollTo = function(_selector, _duration, _adjust) {
//     const selector = _selector || 'body';
//     const targetEle = document.querySelector(selector);
  
//     // - Get current &amp; target positions
//     const scrollEle = document.documentElement || window.scrollingElement;
//     const currentY = scrollEle.scrollTop;
//     const targetY = targetEle.offsetTop;// - (_adjust || 0);
//     animateScrollTo(currentY, targetY, _duration);
  
//     // - Animate and scroll to target position
//     function animateScrollTo(_startY, _endY, _duration) {
//         const duration = _duration || 600;
//         const unitY = (targetY - currentY) / duration;
//         const startTime = new Date().getTime();
//         const endTime = new Date().getTime() + duration;

//         const scrollTo = function() {
//             const now = new Date().getTime();
//             const passed = now - startTime;
//             if (now <= endTime) {
//                 scrollEle.scrollTop = currentY + (unitY * passed);
//                 requestAnimationFrame(scrollTo);
//             } else {
//                 // When selector is body, there is bug, that can be not positioned to 0 scrollY.
//                 if (selector === 'body' && window.scrollY > 0) {
//                     window.scrollTo(0, 0);
//                 }
//             }
//         };
//         requestAnimationFrame(scrollTo);
//     }
// };

const LayoutWrapper = styled.div`
    position: relative;
    width: 100%;

    .${classes.desktop} & {
        min-width: ${size.desktopWidth};
        min-height: 100vh;
        @media only screen and (min-width: ${breakPoint.lg}) {
            min-width: ${size.width};
        }
    }
    .${classes.mobile} & {
        min-width: ${size.mobileWidth};
        min-height: 100vh;
    }
`;
// const MoveTopButtonWrapper = styled.div`
//     position: fixed;
//     bottom: 15px;
//     right: 15px;
//     pointer-events: none;
// `;

const Layout = props => {
    // const [showTopButton, setShowTopButton] = useState(false);
    // const handleMoveTop = () => {
    //     animteScrollTo();
    // };
    // const handleScroll = () => {
    //     if (UserAgent.os.isOthers) return;
    //     if (window.innerHeight * 0.5 < window.scrollY) {
    //         setShowTopButton(true);
    //     } else {
    //         setShowTopButton(false);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, true);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <LayoutWrapper>
            {props.children}
            {/* {!UserAgent.os.isOthers && <MoveTopButtonWrapper><MoveTopButton onClick={handleMoveTop} isVisible={showTopButton} /></MoveTopButtonWrapper>} */}
        </LayoutWrapper>
    );
};

export default Layout;