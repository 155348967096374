import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useCookies } from 'react-cookie';
import { cookieDomain } from '@mirinae/shared/defines/paths';
import Localization from '@mirinae/shared/il8n';
import AnalyzerTopFixedBanner from '@mirinae/explorer/components/common/AnalyzerTopFixedBanner';

const TopBannerMargin = styled.div`
    margin-bottom: 56px;
`;

const AnalyzerTopFixedBannerContainer = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [, setCookie] = useCookies([]);
    
    const handleCloseButton = (e) => {
        e.stopPropagation();
        setShowBanner(false);
        setCookie('closed-top-banner', true, { path: '/', maxAge: 60 * 60 * 24, domain: cookieDomain });
    };

    return showBanner ? (
        <>
            <TopBannerMargin />
            <AnalyzerTopFixedBanner
                localization={Localization}
                onCloseClick={(e) => handleCloseButton(e)}
            />
        </>
    ) : null;
};

export default AnalyzerTopFixedBannerContainer;
