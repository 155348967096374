import React, { useContext } from 'react';
import HtmlParser from 'html-react-parser';

import ModalPopup from '@mirinae/explorer/components/common/ModalPopup';
import GlobalContext from '@mirinae/explorer/modules/defines/contexts';

const ModalPopupContainer = () => {
    const [globalContext, setGlobalContext] = useContext(GlobalContext);
    const handleClose = () => {
        setGlobalContext(prev => ({ ...prev, modalPopup: { show: false, title: '', message: '' } }));
    };

    if (!globalContext.modalPopup.show) return null;
    return (
        <ModalPopup title={globalContext.modalPopup.title} onClose={handleClose}>
            {HtmlParser(globalContext.modalPopup.message)}
        </ModalPopup>
    );
};

export default ModalPopupContainer;