import React from 'react';

import Level0 from '../icons/Level0Small';
import Level1 from '../icons/Level1Small';
import Level2 from '../icons/Level2Small';
import Level3 from '../icons/Level3Small';
import Level4 from '../icons/Level4Small';
import Level5 from '../icons/Level5Small';
import Level6 from '../icons/Level6Small';
import LevelNo from '../icons/LevelNoSmall';
import LevelIdiom from '../icons/LevelIdiomSmall';
import LevelNeo from '../icons/LevelNeoSmall';
import LevelProv from '../icons/LevelProvSmall';

const LevelBadge = props => {
    switch (props.level.toLowerCase()) {
    case '0':
        return <Level0 />;
    case '1':
        return <Level1 />;
    case '2':
        return <Level2 />;
    case '3':
        return <Level3 />;
    case '4':
        return <Level4 />;
    case '5':
        return <Level5 />;
    case '6':
        return <Level6 />;
    case 'idiom':
        return <LevelIdiom />;
    case 'neologism':
    case 'neo':
        return <LevelNeo />;
    case 'proverb':
    case 'prov':
        return <LevelProv />;
    default:
    case 'no':
        return <LevelNo />;
    }
};

export default LevelBadge;