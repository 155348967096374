export const FACEBOOK_APPID = '518859892390398'; // '294833218167908';
export const GOOGLE_CLIENTID = '88024450945-ivs1idd5sd8p1mv4501h7jtpvrr2aga9.apps.googleusercontent.com';
export const GA_TRACKINGID = 'UA-177644098-2'; // GA3
export const GTM_ID = 'GTM-T5JNTJR'; // GTM + GA4
export const FB_PIXELID = '438241593890366';


// google API admin console - https://console.cloud.google.com/apis/dashboard?authuser=3&folder=&organizationId=&project=mirinae-explorer&supportedpurview=project
//   sign in as dev@mirinae.io
//
//  note email-login password change comes from support@mirinae.io (p/w E7%%*neBs)
//
//  Namver/Papago API signup = https://www.ncloud.com/product/aiService/papagoNmt 존형님.
//     I signed up for paying Papago API. id:dev@mirinae.io pwd: Mirinae1005& price plan is not expensive.
//     I will call Naver tomorrow to additional procedure.
//
//  Facebook dev account page - https://developers.facebook.com/apps/2574192039513331/settings/basic/, login johnw@mirinae.io
//    to make releasable, have to register app, have biz FB account, provide EULA and ToS links for app
//