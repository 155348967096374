import React, { useEffect, useState } from 'react';

import { UserAgent, ExternalLinks } from '@mirinae/js-utils';

import MobileStoreBanner from '@mirinae/explorer/components/common/MobileStoreBanner';
import { Event } from '@mirinae/explorer/modules/apis/GA3';
import Localization from '@mirinae/shared/il8n';

const MobileStoreBannerContainer = ({ isApp }) => {
    const agreed = sessionStorage.getItem('agreeweb');
    const [display, setDisplay] = useState(false);
    const { os } = UserAgent;

    useEffect(() => {
        setTimeout(() => {
            if (!isApp
                && (os.isAndroid || os.isIos)
                && agreed !== 'true'
                && !window.location.pathname.match(/account-confirmed/)
                && document.querySelector('body').classList.value.indexOf('inApp') === -1
            ) {
                setDisplay(true);
            }
        }, 2000);
    }, []);

    const handleDeny = () => {
        sessionStorage.setItem('agreeweb', 'true');
    };

    return (
        display ? (
            <MobileStoreBanner
                linkText={Localization.formatString(Localization['ab-text2'], os.isAndroid ? 'Play' : 'App')}
                ignoreText={Localization['ab-text3']}
                link={os.isAndroid ? ExternalLinks.app.android : ExternalLinks.app.apple}
                onConfirm={() => Event('Banner', 'App Banner on Index page', isApp ? 'app' : 'web')}
                onCancel={handleDeny}
                theme="forWeb"
            >
                {Localization['ab-text1']}
            </MobileStoreBanner>
        ) : null
    );
};

export default MobileStoreBannerContainer;