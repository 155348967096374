import React from 'react';

const Apple = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '16'} viewBox="0 0 13 16">
        <path
            d="M4.139,16A1.969,1.969,0,0,1,2.8,15.355a8.266,8.266,0,0,1-1.118-1.387A9.8,9.8,0,0,1,.5,11.534a9.235,9.235,0,0,1-.5-2.9A5.469,5.469,0,0,1,.671,5.86,4.043,4.043,0,0,1,2.077,4.386a3.7,3.7,0,0,1,1.9-.555,4.309,4.309,0,0,1,1.47.355,4.571,4.571,0,0,0,1.166.355,6.885,6.885,0,0,0,1.292-.418,4.547,4.547,0,0,1,1.547-.333c.072,0,.142,0,.209.009a3.712,3.712,0,0,1,2.923,1.6,3.4,3.4,0,0,0-1.724,3.062A3.452,3.452,0,0,0,11.931,11,3.532,3.532,0,0,0,13,11.727c-.089.27-.179.512-.272.742a8.943,8.943,0,0,1-.83,1.547,7.868,7.868,0,0,1-1.069,1.339,2.053,2.053,0,0,1-1.373.627,3.364,3.364,0,0,1-1.269-.314,3.544,3.544,0,0,0-1.366-.313,3.663,3.663,0,0,0-1.4.313A3.706,3.706,0,0,1,4.2,16ZM6.7,3.789c-.061,0-.123,0-.185-.008a2.679,2.679,0,0,1-.018-.3,3.582,3.582,0,0,1,.9-2.262A3.476,3.476,0,0,1,8.485.366,3.174,3.174,0,0,1,9.734,0,2.933,2.933,0,0,1,9.75.32a3.5,3.5,0,0,1-.844,2.238A2.924,2.924,0,0,1,6.7,3.789Z"
            transform="translate(0 0)" style={{ mixBlendMode: 'hard-light', isolation: 'isolate' }}
            opacity={props.theme === 'white' ? '1' : '0.76'}
            fill={props.theme === 'white' ? '#fff' : '#000'} />
    </svg>
);

export default Apple;