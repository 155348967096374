/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/*    Mirinae NLP API client Javascript library
 */
/*
 *    Copyright © 2020, Mirinae Corp. and John Wainwright
 */

import Localization from '@mirinae/shared/il8n';
import { nlpPaths } from '@mirinae/shared/defines/nlppaths';
import { apiPaths } from '@mirinae/shared/defines/paths';
// import { apiPaths } from '@mirinae/shared/defines/paths';
import { httpAPI } from './http';
import { analysisStyle } from '@mirinae/react-ui';

// export constructor for API client instance
//  each instance can be used for sequential analysis calls and retains analysis state for most-recent call to analyze().
//     Create new instances for concurrent analysis calls.
export const NlpAPI = (() => {

    // NLP client constructor
    function NlpAPIConstructor(nlpAPIHost) {
        // client instance vars
        this.apiHost = nlpAPIHost;
        this.session = null;
    }

    NlpAPIConstructor.prototype = {

        // ----------  API calls  ---------

        analyze (text, user, analyzeOptions) {
            // returns a Promise that resolves to analysis result + exploration session token (eSessionID), partial if "continued": true is present
            this.user = user;
            this.analyzeOptions = analyzeOptions;
            this.text = text;
            // set-up call
            console.log('analyze', Localization.getLanguage());
            const callOptions = { data: { text, user, options: { ...analyzeOptions, language: Localization.getLanguage() } } };
            return httpAPI(this.apiHost, nlpPaths.analyze, callOptions) // todo: ensure cancel prior promise if called while still in flight
                .then(data => {
                    // grab returned eSession token, etc. & pass on response
                    this.session = data.response.session;
                    return data;
                });
        },

        analyzeNext (fromPart) {
            console.log('analyzeNext', Localization.getLanguage());
            // returned Promise resolves to subsequent parts of analysis; continue to call analyzeNext until resolved result has no "continued" or "continued": false
            // IMPORTANT: this assumes the context of the most-recent call to analyze; to perform multiple concurrent analyses, use multiple NLPClient instances
            const callOptions = { data: { fromPart, user: this.user, session: this.session, options: { ...this.analyzeOptions, language: Localization.getLanguage() } } };
            return httpAPI(this.apiHost, nlpPaths.analyzeNext, callOptions);
        },

        reanalyze (optionUpdates) {
            console.log('reanalyze', Localization.getLanguage());
            // start a fresh analysis on last analyzed text with update analysis options
            const updatedOptions = { ...this.analyzeOptions, ...optionUpdates };
            const callOptions = { data: { text: this.text, user: this.user, session: this.session, options: { ...updatedOptions, language: Localization.getLanguage() } } };
            return httpAPI(this.apiHost, nlpPaths.reanalyze, callOptions);
        },

        setSessionFromBookmark (bookmark, analysis) {
            // update my session state to that in the given bookmark & its associated analysis
            this.user = bookmark.user.$oid;
            this.session = bookmark.session.$oid;
            const a = analysis[0].subparts[0];
            this.analyzeOptions = a.options;
            this.text = a.sourceText;
        },

        bookmark (type, name, text, uiState, options) {
            console.log('bookmark', Localization.getLanguage());
            // record a named user analyis bookmark in the given UI state; name optional, type = 'current' tracks current drilldown state for a session
            const callOptions = { data: { uiState, type, name, text, user: this.user, session: this.session, options: { ...options, language: Localization.getLanguage() } } };
            return httpAPI(this.apiHost, nlpPaths.bookmark, callOptions);
        },

        setWordMeaning (morph, sentenceIndex, wordIndex, meaning, contextWords) {
            const callOptions = { data: { morph, sentenceIndex, wordIndex, meaning, contextWords, session: this.session, options: { language: Localization.getLanguage() } } };
            return httpAPI(this.apiHost, nlpPaths.setWordMeaning, callOptions);
        },

        setParseTree (sentenceIndex, treeHash) {
            const callOptions = { data: { sentenceIndex, treeHash, session: this.session } };
            return httpAPI(this.apiHost, nlpPaths.setParseTree, callOptions);
        },

        getRelatedLessonsAndDisccussions (sentence) {
            // retrieve summary info about discussions and lessons related to the patterns in a given sentence analysis
            const callOptions = { data:
                    { patterns: Object.values(sentence.patterns)
                        .filter(p => p.gp.patternType !== 'Wikinae')
                        .map(p => ({ id: p.gp._id.$oid, lessonID: p.gp.lessonID, patternType: p.gp.patternType, referenceDef: p.gp.referenceDef })),
                      language: Localization.getLanguage() } };
            return httpAPI(this.apiHost, nlpPaths.getRelatedLessonsAndDisccussions, callOptions);
        },

        getGlossaryEntry (glossaryKey) {
            // retrieve glossary entries for the give glossary key
            const callOptions = {
                data: {
                    searchText: glossaryKey,
                    searchField: 'Keyword',
                    language: Localization.getLanguage(),
                },
            };
            return httpAPI('', apiPaths.listGlossary, callOptions);
        },

        // ---- one-time call to get complete analysis in a single promise-returning call

        getCompleteAnalysis (text, { user = null, analysisOptions = { }, displayOptions = { } }) {
            // analysis/continuation request loop, returns a promise that resolves to the entire analysis
            // prep analysis options

            const displayOpts = {
                ...analysisStyle.displayOptionsInit,
                scaleToFitWidth: null,
                autoScaleToFit: {
                    width: 600,
                    minScale: 0.7,
                },
                phraseDisplayMode: 'tree',
                fontSize: 15,
                ...displayOptions,
            };

            const analysisOpts = {
                enableTranslation: true,
                disableCorrections: false,
                forceReanalyze: true,
                allParseTreeLevels: false,
                enableCompactParseTree: true,
                enabledTranslation: true,
                zoomedPatterns: [],
                enableAutoScaleToFit: true,
                enableDebugging: false,
                enablePartialParseOnError: false,
                ignoreSpacingErrors: false,
                ...analysisOptions,
            };

            return new Promise((resolve, reject) => {
                // call NLPClient for initial analysis
                this.analyze(text, user, analysisOpts)
                    .then(response => {
                        // extract response details
                        const { analysis, session } = response.response;
                        const analysisUpdate = { analysis: { continued: false, queuedUpdates: [] } };
                        analysisUpdate.session = session;
                        analysisUpdate.analysis = this.mergeAnalysisParts(analysisUpdate.analysis, analysis, displayOpts);
                        // issue request for any continuations
                        const continuationLoop = () => {
                            this.analyzeNext(analysisUpdate.analysis.nextPart)
                                .then(response => {
                                    const { analysis } = response.response;
                                    analysisUpdate.analysis = this.mergeAnalysisParts(analysisUpdate.analysis, analysis, displayOpts);
                                    // recurse on continuation-requests until none left
                                    if (analysisUpdate.analysis.continued) {
                                        continuationLoop();
                                    } else {
                                        resolve(analysisUpdate.analysis);
                                    }
                                })
                                .catch((error) => reject(error));
                        }
                        if (analysisUpdate.analysis.continued) {
                            continuationLoop();
                        } else {
                            resolve(analysisUpdate.analysis);
                        }
                    })
                    .catch((error) => {
                        resolve(null);
                    });
            });
        },

        // -----------  client-side utility functions  ----------

        mergeAnalysisParts(analysis, analysisUpdate, displayOptions) {
            // merge analysis parts in response into given local analysis; set analysis.continued and analysis.nextPart as need for further continuations
            // if a new sentence analysis, pass displayOptions options onto buildDisplay() to lay-out sentence display coordinates
            const mergedAnalysis = analysis;
            // add individual updates to an update queue and apply those that are able to be applied
            analysisUpdate.forEach(src => {
                if (src.part === 0) {
                    // grab header contents of part 0, subpart 0
                    const analysisHeader = src.subparts[0];
                    Object.assign(mergedAnalysis, analysisHeader);
                    if ('sentence' in analysisHeader)
                        delete mergedAnalysis.sentence;
                }
                // response may have multiple sub-parts
                src.subparts.forEach(sp => {
                    if ('sentence' in sp)
                        analysis.queuedUpdates.splice(0, 0, sp); // stick sentence updates at the front so they enable any early partial-results for them
                    else
                        analysis.queuedUpdates.push(sp);
                });
                // track latest part's continuation
                mergedAnalysis.continued = src.continued;
                mergedAnalysis.nextPart = src.part + 1;
            });
            // apply queued updates, retraining those not yet able to be applied
            analysis.queuedUpdates = this.applyUpdates(analysis.queuedUpdates, mergedAnalysis, displayOptions);
            if (!mergedAnalysis.continued) {
                // end of results, apply all remaining updates
                this.applyUpdates(analysis.queuedUpdates, mergedAnalysis, displayOptions);
            }
            //
            return mergedAnalysis;
        },

        applyUpdates(updates, mergedAnalysis, displayOptions) {
            // appply & filter out applyable updates, retaining those waiting for their host sentence to show up
            const remainingUpdates = updates.filter(update => {
                // each kind of update is signalled by key field
                if ('sentence' in update) {
                    let si = 0;
                    if (!mergedAnalysis.sentences) {
                        mergedAnalysis.sentences = [];
                    }
                    // merge in the sentence in the response
                    si = update.sentence.index;
                    mergedAnalysis.sentences[si] = update.sentence;

                    // a build the new sentence's display layout
                    this.zoomParseTree(mergedAnalysis.sentences[si], displayOptions.compactParseTree);
                    this.buildDisplay(mergedAnalysis.sentences[si], displayOptions);
                    this.buildSourceSpans(mergedAnalysis, displayOptions);
                } else if ('phraseParse' in update) {
                    // phrase-structure parsing
                    const s = mergedAnalysis.sentences[update.phraseParse.sentenceIndex];
                    if (!s)
                        return true;
                    s.parseTrees = update.phraseParse.parseTrees;
                    s.treeIndex = update.phraseParse.treeIndex;
                    s.error = update.phraseParse.error;
                    this.zoomParseTree(s, displayOptions.compactParseTree);
                    this.buildDisplay(s, displayOptions);
                    this.buildSourceSpans(mergedAnalysis, displayOptions);
                } else if ('sentenceTranslation' in update) {
                    // sentence translation
                    const s = mergedAnalysis.sentences[update.sentenceTranslation.sentenceIndex];
                    if (!s)
                        return true;
                    s.translation = update.sentenceTranslation.translation;
                    s.translationService = update.sentenceTranslation.translationService;
                    this.buildDisplay(s, displayOptions);
                } else if ('dictionaryUpdate' in update) {
                    // word-def update
                    const du = update.dictionaryUpdate;
                    const s = mergedAnalysis.sentences[du.sentenceIndex];
                    if (!s)
                        return true;
                    if (du.displayDef)
                        s.displayDefs[du.morph] = du.displayDef;
                    s.mappedPosList[du.posIndex].translation = du.translation;
                    s.mappedPosList[du.posIndex].isSetMeaning = Boolean(du.isSetMeaning);
                    this.buildDisplay(s, displayOptions);
                } else if ('wordMeaningUpdates' in update) {
                    // word-meaning updates
                    const wmu = update.wordMeaningUpdates;
                    const s = mergedAnalysis.sentences[wmu.sentenceIndex];
                    if (!s)
                        return true;
                    wmu.updates.forEach(mu => {
                        s.mappedPosList[mu.posIndex].translation = mu.meaning;
                        s.mappedPosList[mu.posIndex].isSetMeaning = true;
                        s.mappedPosList[mu.posIndex].notSure = Boolean(mu.notSure);
                        // if (mu.notSure) {
                        //     s.mappedPosList[mu.posIndex].notSure = true;
                        // }
                    });
                    this.buildDisplay(s, displayOptions);
                } else if ('wordSampleTranslation' in update) {
                    // word-def sample sentence translation
                    const wst = update.wordSampleTranslation;
                    const s = mergedAnalysis.sentences[wst.sentenceIndex];
                    if (!s)
                        return true;
                    const vocab = s.displayDefs[wst.vocabKey];
                    let i;
                    let j;
                    const k = wst.sampleIndex;
                    vocab.wordDefs[i][j].examples[k].translation = wst.translation;
                    this.buildDisplay(s, displayOptions);
                }
            });
            return remainingUpdates;
        },

        buildDisplay(sentence, displayOptions) {
            // compute bounds & coordinates for the mapped phoneme + POS elements for given sentence
            // displayOptions: see definitions https://bitbucket.org/mirinae-explorer/nlp-front-end-sdk/src/master/notes/analysis-display-layout-details.png
            const s = sentence;
            const si = s.index;
            const dopts = { ...displayOptions.layout };
            const phraseBoxMode = displayOptions.phraseDisplayMode === 'box';
            if (phraseBoxMode) {
                // spread things out for box diplay mode
                dopts.morphemeGap *= 1.25;
                // dopts.wordGap *= 2.5;
                // dopts.lineGap *= 1.35;
                dopts.originY += 40;
            }
            let x = dopts.originX;
            let y = dopts.originY;
            // const sentenceBase = dopts.originY;
            let maxY = 0;
            let maxX = 0;

            s.selectedPosID = null;
            s.selected = {};
            s.index = si;

            // if any idiom or sentence patterns, layout vertical coords above main word/POS section & grab bounding POS for later
            //   horizontal layout.  current assumption is max one idiom & one sentence pattern per sentence(??)
            const idioms = []; const 
                sentencePats = [];
            Object.keys(s.patterns).forEach(pk => {
                const p = s.patterns[pk];
                if (p.gp.patternType.match(/Idiom|Proverb/)) {
                    p.posIndexes.forEach(pi => {
                        idioms.push({
                            pattern: p.gp.id,
                            domID: `exp-idiom-${si}-${s.mappedPosList[pi[0]].index}`,
                            pos0: s.mappedPosList[pi[0]],
                            posN: s.mappedPosList[pi[1]],
                            sentence: s,
                            label: p.gr.title,
                            y: y + dopts.fontHeight.idiom - dopts.fontDescent.idiom - (phraseBoxMode ? 45 : 8),
                        });
                    });
                } else if (p.gp.patternType === 'Sentence pattern') {
                    p.posIndexes.forEach(pi => {
                        sentencePats.push({
                            pattern: p.gp.id,
                            domID: `exp-sentencePat-${si}-${s.mappedPosList[pi[0]].index}`,
                            word0: s.words[s.mappedPosList[pi[0]].wordIndex],
                            wordN: s.words[s.mappedPosList[pi[1]].wordIndex],
                            sentence: s,
                            label: p.gr.title,
                            y,
                        });
                    });
                } else
                    return;
                y += dopts.fontHeight.idiom + dopts.bracketHeight + dopts.bracketGap + 4;
            });
            s.idioms = idioms;
            s.sentencePats = sentencePats;
            //
            this.guessWordMeanings(s);

            if (s.endingPunctuation) {
                // add in any special sentence-ending-morpheme punctuation
                const em = s.mappedPosList[s.mappedPosList.length - 2]; // last but one
                if (em.tag.endsWith('EF') && !em.phoneme.match(/[!?]$/))
                    em.phoneme += s.endingPunctuation;
            }

            // if in phrase-box mode, first find phrase boxings so spacing between them can be set here
            if (phraseBoxMode)
                this.findPhraseBoxes(s);

            // loop over parts-of-speech computing and attaching layout coords for each phoneme
            // at this point, 'y' points at top of word boxes
            s.mappedPosList.forEach(pos => makeLabelLines(s, pos, displayOptions));
            s.mappedPosList.forEach((pos, i) => {
                pos.sentence = s;
                pos.domID = `lex-pos-${si}-${i}`;

                // figure phoneme text metrics
                pos.pWidth = getLayoutElement(pos.phoneme, 'analysis-phoneme').getComputedTextLength();
                const lWidth = Math.max.apply(null, pos.labels.map(l => getLayoutElement(l.text, 'analysis-poslabel-role').getComputedTextLength()));
                pos.width = Math.max(pos.pWidth, lWidth);
                // center phoneme text in bounding box
                pos.x = x + pos.width / 2;
                // console.log('pos', pos.morph, pos.x - pos.width / 2, pos.x + pos.width / 2);
                // bump past original words + contribution line
                pos.y = y + (phraseBoxMode ? 0 : dopts.fontHeight.word + dopts.fontHeight.morpheme - dopts.fontDescent.morpheme + dopts.lineGap);
                maxX = Math.max(maxX, x + pos.width);
                // lay out label lines
                let labelY = pos.y + dopts.fontDescent.morpheme + dopts.posGap + dopts.fontHeight.posLabel - dopts.fontDescent.posLabel;
                const lineGap = dopts.fontHeight.posLabel * 1.2 + dopts.fontDescent.posLabel * 2;
                if (phraseBoxMode) {
                    // only meaning lines in phrase box mode, if any pos has multiple lines then all use spacing for two lines, center vertically if one, elipsis at end of second line if > 2
                    const meaningLines = pos.labels.filter(l => l.type === 'meaning');
                    if (s.multiMeaningLines) {
                        if (meaningLines.length === 1)
                            Object.assign(meaningLines[0], { x: pos.x, y: labelY + dopts.fontHeight.posLabel / 2 + dopts.fontDescent.posLabel });
                        else if (meaningLines.length > 1) {
                            Object.assign(meaningLines[0], { x: pos.x, y: labelY });
                            Object.assign(meaningLines[1], { x: pos.x, y: labelY + lineGap / 1.2 });
                        }
                        if (meaningLines.length > 2) {
                            meaningLines[1].title = meaningLines[1].text;
                            meaningLines[1].text = `${meaningLines[1].text.substring(0, meaningLines[1].text.length - 2)}...`;
                        }
                        pos.bottom = labelY + lineGap;
                    } else {
                        if (meaningLines[0]) Object.assign(meaningLines[0], { x: pos.x, y: labelY });
                        pos.bottom = labelY + dopts.fontDescent.posLabel;
                    }
                } else {
                    pos.labels.forEach((line, i) => {
                        Object.assign(line, { x: pos.x, y: labelY });
                        if (i < pos.labels.length - 1) {
                            labelY += lineGap;
                            if (line.type === 'meaning' && pos.labels[i + 1].type !== 'meaning')
                                labelY += dopts.fontHeight.posLabel * 0.45; // more space between meaning & role lines
                        }
                    });
                    pos.bottom = labelY + dopts.fontDescent.posLabel + dopts.bracketGap;
                }

                // overall bounds
                maxY = Math.max(maxY, pos.bottom);
                pos.bounds = {
                    left: x - 2,
                    top: pos.y - dopts.fontHeight.morpheme - 2,
                    width: pos.width + 4,
                    height: pos.bottom - (pos.y - dopts.fontHeight.morpheme) + 2,
                };
                // bump x to next POS location
                x += pos.width + (i > 0 && pos.endOfWord && !pos.endOfPhraseBox ? dopts.wordGap : dopts.morphemeGap);
                if (phraseBoxMode)
                    x += pos.endOfClause ? dopts.clauseBoxGap : pos.endOfPhraseBox ? dopts.phraseBoxGap : 0;
            });

            // for each word in sentence, use wordSpan to pick up positioning from associated mapped poslist entries layed out above
            // y still at top of word boxes
            s.words.forEach(w => {
                // pull out word-span details
                let posIndex; let offset;
                const [start, end] = w.wordSpan;
                [posIndex, offset] = start;
                let pos = s.mappedPosList[posIndex];
                // we have word-starts owning mappedPos entry & offset in its phoneme, compute x-bounds from text-layout metrics
                let ttw = getLayoutElement(pos.phoneme, 'analysis-phoneme');
                w.left = pos.x - pos.pWidth / 2 + ttw.getStartPositionOfChar(offset).x + 1;
                // do same for word end
                [posIndex, offset] = end;
                pos = s.mappedPosList[posIndex];
                ttw = getLayoutElement(pos.phoneme, 'analysis-phoneme');
                w.right = pos.x - pos.pWidth / 2 + ttw.getEndPositionOfChar(offset).x - 1;
                maxX = Math.max(maxX, w.right);
                // position between bounds
                w.x = w.left + (w.right - w.left) / 2;
                w.y = y + dopts.fontHeight.word - dopts.fontDescent.word;
                // construct morpheme-grouping line SVG path
                w.linePath = `M ${w.left} ${y + dopts.fontHeight.word + dopts.lineGap / 2 - 2} h ${w.right - w.left}`;
            });

            // lay out idiom annotation horizontal coords
            idioms.forEach(id => {
                id.left = id.pos0.bounds.left;
                id.right = id.posN.bounds.left + id.posN.bounds.width;
                maxX = Math.max(maxX, id.right);
                id.x = id.left + (id.right - id.left) / 2;
                const bracketY = id.y + dopts.fontHeight.idiom - 4;
                id.bounds = {
                    left: id.left - 2,
                    top: id.y - 2,
                    width: id.right - id.left + 4,
                    height: bracketY + dopts.bracketHeight + dopts.bracketGap - id.y + 2,
                };
                id.bracketPath = `M ${id.left} ${bracketY + dopts.bracketGap + 4}`
                                 + ` q 0 ${-dopts.bracketHeight} 10 ${-dopts.bracketHeight}`
                                 + ` H ${id.left + dopts.bracketHeight} ${id.right - dopts.bracketHeight} `
                                 + ` q ${dopts.bracketHeight} 0 ${dopts.bracketHeight} ${dopts.bracketHeight}`;
            });

            // lay out sentencePat annotation horizontal coords
            // for now, the sentence-pattern includes a brace that covers the key pattern section *plus* the whole sentence
            const sentenceLeft = s.words[0].left - 5;
            const sentenceRight = s.words[s.words.length - 1].right + 5;
            sentencePats.forEach(sp => {
                sp.left = sp.word0.left - 5;
                sp.right = sp.wordN.right + 5;
                maxX = Math.max(maxX, sp.right);
                sp.x = (sp.right - sp.left) / 2;
                const bracketY = sp.y + dopts.fontHeight.idiom;
                sp.bounds = {
                    left: sp.left - 2,
                    top: sp.y - 2,
                    width: sp.right - sp.left + 4,
                    height: bracketY + dopts.bracketHeight + dopts.bracketGap - sp.y + 2,
                };
                sp.bracketPath = `M ${sp.left} ${sp.y + dopts.bracketGap}`
                                 + ` q 0 ${-dopts.bracketHeight} 10 ${-dopts.bracketHeight}`
                                 + ` H ${sp.left + dopts.bracketHeight} ${sp.right - dopts.bracketHeight} `
                                 + ` q ${dopts.bracketHeight} 0 ${dopts.bracketHeight} ${dopts.bracketHeight}`;
                sp.sentenceBracketPath = `M ${sentenceLeft} ${sp.y + dopts.bracketGap}`
                                 + ` q 0 ${-dopts.bracketHeight} 10 ${-dopts.bracketHeight}`
                                 + ` H ${sentenceLeft + dopts.bracketHeight} ${sentenceRight - dopts.bracketHeight} `
                                 + ` q ${dopts.bracketHeight} 0 ${dopts.bracketHeight} ${dopts.bracketHeight}`;
            });

            // construct phrase parse-tree layout
            let minTreeX = dopts.originX;
            let maxTreeX = 0; // track tree label bounds
            const treeStartY = maxY;
            if (s.treeIndex !== undefined && s.parseTrees.length > s.treeIndex && displayOptions.showParseTree) {
                const layout = this.layoutTree(s, s.treeIndex, displayOptions);

                // compute tree layout coords
                // y still at top of word boxes, maxY below POS labels, point at layer 0 bracket lines
                s.maxY = maxY; // for testing
                y = maxY + dopts.bracketGap + dopts.bracketHeight;
                // draw an inverted tree from the terminal layer down; add terminal layer branchPaths as needed,
                //   find parent & siblings & layout parent midway between siblings
                const layers = layout.layers;
                for (let layerIndex = 0; layerIndex < layout.layers.length; layerIndex += 1) {
                    const entries = layers[layerIndex];
                    for (let nodeIndex = 0; nodeIndex < entries.length; nodeIndex += 1) {
                        const node = entries[nodeIndex];
                        const pos = s.mappedPosList[node.posIndex];
                        node.pos = pos;
                        if (layerIndex === 0) {
                            // terminal layer, add SVG branchPaths for inner terminals
                            // if (pos.unexpected && false) { // already inserted???
                            //     // if the phrase-parsing fails, the first unexpected POS has this field true; add a top layer node 'Unexpected'
                            //     //   and branchPath down to failing POS terminal
                            //     const uNodeY = y + (layers.length - 1) * (dopts.bracketGap + dopts.fontHeight.treeLabel + dopts.fontDescent.treeLabel + dopts.bracketHeight);
                            //     const uNode = {
                            //         label: { x: pos.x, y: uNodeY, text: 'Unexpected' },
                            //         level: 1,
                            //         children: [node],
                            //     };
                            //     layers[layers.length - 1].push(uNode);
                            // }
                            node.x = pos.x;
                            node.y = pos.bottom;
                        } else {
                            // tree nodes
                            node.y = y + (layerIndex - 1) * (2 * dopts.bracketGap + dopts.nodeLabelGap + dopts.fontHeight.treeLabel + dopts.bracketHeight + 6);
                            maxY = Math.max(maxY, node.y + dopts.bracketGap + dopts.fontHeight.treeLabel);
                            // get children bounds & center me within that
                            const c0 = node.children[0]; const cn = node.children[node.children.length - 1];
                            let x0; let 
                                xn = 0;
                            if (c0.type === 'word') {
                                const pos = s.mappedPosList[c0.posIndex];
                                c0.x = pos.x;
                                c0.width = pos.width;
                            }
                            if (cn.type === 'word') {
                                const pos = s.mappedPosList[cn.posIndex];
                                cn.x = pos.x;
                                cn.width = pos.width;
                            }
                            if (node.children.length > 0) {
                                x0 = c0.x;
                                xn = cn.x;
                            } else {
                                x0 = c0.x;
                            }
                            node.x = (x0 + xn) / 2;
                            node.label = {
                                x: node.x,
                                y: node.y + dopts.nodeLabelGap + dopts.fontHeight.treeLabel,
                                text: node.label || node.tag,
                            };

                            // track label x-bounds to ensure node labels visible
                            const wo2 = getLayoutElement(node.label.text, 'analysis-treelabel').getComputedTextLength() / 2;
                            node.label.box = { x: node.x - wo2 - 5, y: node.label.y - dopts.fontHeight.treeLabel - 5, width: wo2 * 2 + 10, height: dopts.fontHeight.treeLabel + 10 };
                            minTreeX = Math.min(minTreeX, node.x - wo2);
                            maxTreeX = Math.max(maxTreeX, node.x + wo2);

                            // construct SVG bracket path to all the children of this node
                            const bh = dopts.bracketHeight;
                            let path = '';
                            node.children.forEach((c, ci) => {
                                const cy = c.layer > 0 ? c.y + 2 * dopts.bracketGap + dopts.nodeLabelGap + dopts.fontHeight.treeLabel + 6 : c.y;
                                const dy = node.y - cy;
                                path += `M ${c.x} ${cy} `;
                                if (node.children.length === 1)
                                    path += `v ${dy} `;
                                else if (ci === 0)
                                    path += `v ${dy - bh} q 0 ${bh} ${bh} ${bh} H ${node.x} `;
                                else if (ci === node.children.length - 1)
                                    path += `v ${dy - bh} q 0 ${bh} ${-bh} ${bh} H ${node.x} `;
                                else
                                    path += `v ${dy} `;
                            });
                            node.bracketPath = path;
                        }
                    }
                }
                s.parseTreeDisplay = {
                    tree: layout.tree,
                    layers,
                    bounds: {
                        left: minTreeX, /* layers[0][0].pos.x */
                        top: y,
                        width: maxTreeX - minTreeX, /* layers[0][layers[0].length - 1].pos.x - layers[0][0].pos.x, */
                        height: maxY,
                    },
                };
            }

            // construct phrase boxes if in box mode
            if (displayOptions.phraseDisplayMode === 'box') {
                [maxX, maxY] = this.layoutPhraseBoxes(s, maxX, treeStartY, displayOptions);
            }

            const sentenceWidth = Math.max(maxX, maxTreeX) - minTreeX + dopts.originX;
            const left = Math.min(minTreeX, dopts.originX);
            s.bounds = {
                left,
                top: dopts.originY,
                width: sentenceWidth - left,
                height: maxY - dopts.originY,
            };

            // SVG controls
            maxY += 20; // some fuzz
            console.log('computing scale', dopts.overallScale, displayOptions.fontSize);
            let scale = dopts.overallScale[displayOptions.fontSize];
            let scaledSentenceWidth = sentenceWidth;
            let scaledMaxY = maxY;
            if (displayOptions.fontSize === 'normal') { // don't autoscale if large or small fontsize selected by user
                if (displayOptions.scaleToFitWidth) {
                    // scale if scaleToFit width supplied
                    const newWidth = displayOptions.scaleToFitWidth;
                    scale = newWidth / sentenceWidth;
                } else if (displayOptions.autoScaleToFit && sentenceWidth > displayOptions.autoScaleToFit.width) {
                    // auto scale-down to displayOptions.autoScaleToFit.width but only to displayOptions.autoScaleToFit.minScale
                    const newWidth = displayOptions.autoScaleToFit.width;
                    scale = Math.max(displayOptions.autoScaleToFit.minScale, newWidth / sentenceWidth);
                }
            }
            scaledMaxY *= scale;
            scaledSentenceWidth *= scale;
            //
            const translateStr = left < dopts.originX ? `translate(${dopts.originX - left} 0)` : '';
            const scaleStr = `scale(${scale}  ${scale}) `;
            s.svg = {
                viewBox: `0 0 ${sentenceWidth} ${maxY}`, // ignore this, don't use it
                minWidth: scaledSentenceWidth,
                minHeight: scaledMaxY,
                jsxStyle: { minWidth: `${scaledSentenceWidth}px`,
                    minHeight: `${scaledMaxY}px`,
                    width: `${scaledSentenceWidth}px`,
                    height: `${scaledMaxY}px` },
                transform: scaleStr + translateStr,
                scale,
                translate: Math.min(0, dopts.originX - left),
            };
        },

        // recomputes parse-tree layout based on node collapse/expand settings
        layoutTree(sentence, treeIndex) { // layoutTree(sentence, treeIndex, displayOptions) {
            // construct a temp side-tree respecting current collapse settings
            const terminals = []; const 
                allNodes = [];
            function buildTree(node, parent) {
                if (node.type === 'tree') {
                    if (node.collapse) {
                        // collapse me, return my children tbe be concatenated in at my level
                        let nodes = [];
                        node.children.forEach(c => {
                            nodes = nodes.concat(buildTree(c, parent));
                        });
                        return nodes;
                    } else {
                        const newNode = { type: 'tree',
                            children: [],
                            parent,
                            layer: 1,
                            tag: node.tag,
                            label: node.label,
                            node,
                            level: node.level };
                        node.children.forEach(c => {
                            newNode.children = newNode.children.concat(buildTree(c, newNode));
                        });
                        allNodes.push(newNode);
                        return [newNode];
                    }
                } else {
                    const newNode = { type: 'word',
                        word: node.word,
                        parent,
                        layer: 0,
                        level: parent.level + 1,
                        tag: node.tag,
                        posIndex: node.posIndex,
                        node };
                    terminals.push(newNode); allNodes.push(newNode);
                    return [newNode];
                }
            }
            const tree = buildTree(sentence.parseTrees[treeIndex].tree, null)[0];

            // build layering
            let maxLayer = 0; let 
                tempNodes = terminals;
            while (tempNodes.length > 0) {
                const parents = [];
                // eslint-disable-next-line no-loop-func
                tempNodes.forEach(n => {
                    const parent = n.parent;
                    if (parent) {
                        parent.layer = Math.max(n.layer + 1, parent.layer);
                        maxLayer = Math.max(maxLayer, parent.layer);
                        parents.push(parent);
                    }
                });
                tempNodes = parents;
            }
            // add nodes to their assigned layer,
            const layers = [];
            allNodes.forEach(n => {
                // if (false && n.tag === 'Unexpected') {
                //     // push the unexpected error node down to bottom layer for better visibility  NOt sure we should do this, disable for now.
                //     n.layer = maxLayer - 1;
                //     n.unexpected = true;
                // }
                // determine if this node should actually be visible (used to be handled in UI display, but should be computed here to get SVG bounds right)
                // only non-terminals (non POS nodes) and
                //    only level 0 nodes providing there are no terminals in its children (we don't want them appearing orphaned)
                if (n.type === 'word' || n.layer > 0 && (n.level > 0 || (n.children && n.children.some(c => c.type === 'word')))) {
                    if (!layers[n.layer])
                        layers[n.layer] = [];
                    layers[n.layer].push(n);
                }
            });
            //
            return { layers, tree };
        },

        // find phrase-boxings if in 'box' mode
        findPhraseBoxes(s) {
            const phraseBoxes = [];
            const pos = (node) => s.mappedPosList[node.posIndex];
            const findBoxings = (node, parentIsClause) => {
                if (node.type === 'word' || !node.tag.match(/sentence/i)) {
                    // find clauses and highest non-clauses, gather their terminals
                    const terminals = [];
                    const gatherTerminals = (pn) => {
                        if (pn.type === 'word')
                            terminals.push(pn);
                        else
                            pn.children.forEach(pnc => gatherTerminals(pnc));
                    };
                    gatherTerminals(node);
                    const boxLabel = node.type === 'tree' ? (node.label || node.tag) : pos(node).label;
                    const boxTag = node.tag;
                    const [startPOS, endPOS] = [pos(terminals[0]), pos(terminals[terminals.length - 1])];
                    const isClause = Boolean(node.tag.match(/clause/i));
                    endPOS.endOfPhraseBox = true;
                    if (isClause) endPOS.endOfClause = true;
                    phraseBoxes.push({ node, boxLabel, boxTag, startPOS, endPOS, isClause, parentIsClause });
                    if (isClause) {
                        node.children.forEach(nc => findBoxings(nc, true));
                    }
                } else {
                    node.children.forEach(nc => findBoxings(nc));
                }
            };
            findBoxings(s.parseTrees[0].tree);
            s.phraseBoxes = phraseBoxes;
        },

        // layout phrase boxes if in 'box' display mode; just tree 0 for now todo: shift all the magic numbers below into dopts
        layoutPhraseBoxes(s, maxX, maxY, displayOptions) {
            let [boxesMaxX, boxesMaxY] = [maxX, maxY];
            // compute box geom
            const [marginTop, marginRight, marginBottom, marginLeft] = [12, 12, 13, 12];
            s.phraseBoxes.forEach(pb => {
                if (pb.isClause) {
                    // compute bracket geometry for clause nodes
                    const [left, top, right] = [
                        pb.startPOS.bounds.left - marginLeft,
                        pb.startPOS.bounds.top + pb.startPOS.bounds.height + 17 + marginTop + marginBottom + displayOptions.layout.fontHeight.treeLabel,
                        pb.endPOS.bounds.left + pb.endPOS.bounds.width,
                    ];
                    const [center, h] = [(left + right) / 2, 10];
                    const path = `M ${left} ${top} q 0 ${h} ${h} ${h} H ${right} q ${h} 0 ${h} ${-h}`;
                    const [nodeX, nodeY, labelX, labelY] = [center - 9, top, center, top + 29 + displayOptions.layout.fontHeight.treeLabel];
                    Object.assign(pb, { x: left, width: right - left, path, nodeX, nodeY, labelX, labelY, isClause: true });
                } else {
                    // compute box geometry for phrase nodes
                    const [x, y, width, height] = [
                        pb.startPOS.bounds.left - marginLeft,
                        pb.startPOS.bounds.top - marginTop,
                        (pb.endPOS.bounds.left + pb.endPOS.bounds.width) - pb.startPOS.bounds.left + marginRight + marginLeft,
                        pb.startPOS.bounds.height + marginTop + marginBottom,
                    ];
                    const [labelX, labelY] = [
                        x + width / 2,
                        y + height + 10 + displayOptions.layout.fontHeight.treeLabel,
                    ];
                    Object.assign(pb, { x, y, width, height, labelX, labelY });
                }
                boxesMaxX = Math.max(boxesMaxX, pb.x + pb.width);
                boxesMaxY = Math.max(boxesMaxY, pb.labelY - displayOptions.layout.fontHeight.treeLabel);
            });
            return [boxesMaxX, boxesMaxY];
        },

        // marshal sentence & clause source spans & scroll-positions; this is used for laying out the multi-line, multi-sentence input UI
        buildSourceSpans(analysis, displayOptions) {
            analysis.sentences.map((s, i) => {
                // find clause spans by finding bounding terminals under the level 1 nodes the parse tree
                const clauseSpans = [];
                const findClauseSpans = (n, span) => {
                    let clauseSpan = span;
                    if (!n || n.level === 0 && (n.tag === 'Phrase structure unavailable' || n.tag === 'Unexpected')) {
                        // span entire sentence if no parse-tree yet or parsing fails
                        clauseSpans.push({
                            index: 0,
                            start: analysis.sourceSpans[i].span[0],
                            end: analysis.sourceSpans[i].span[1],
                            text: analysis.sourceSpans[i].text,
                            left: s.bounds.left,
                            width: s.bounds.width,
                            scrollPos: s.bounds.left * s.svg.scale,
                        });
                        return;
                    }
                    if (n.type === 'tree') {
                        if (n.level === 1) {
                            clauseSpan = { index: clauseSpans.length, tag: n.tag };
                            clauseSpans.push(clauseSpan);
                        }
                        n.children.forEach(c => findClauseSpans(c, clauseSpan));
                    } else {
                        if (n.level === 1) {
                            // level 1 terminal, it gets a span too
                            clauseSpan = { index: clauseSpans.length };
                            clauseSpans.push(clauseSpan);
                        }
                        if (clauseSpan) {
                            if (clauseSpan.start === undefined) {
                                clauseSpan.start = n.pos.sourceSpan[0];
                                clauseSpan.left = n.pos.bounds.left;
                            }
                            // incorprate trailing sentence-final in last clause, if present
                            const endPos = n.pos.index === s.mappedPosList.length - 2 && s.mappedPosList[n.pos.index + 1].tag === 'SF'
                                ? s.mappedPosList[n.pos.index + 1]
                                : n.pos;
                            clauseSpan.end = endPos.sourceSpan[1];
                            // pick up spanned text, compute scroll-pos to center this punter
                            clauseSpan.width = endPos.bounds.left + endPos.bounds.width - clauseSpan.left;
                            clauseSpan.text = s.sourceText.substring(clauseSpan.start, clauseSpan.end);
                            const scrollPos = Math.min(clauseSpan.left, Math.max(0, clauseSpan.left + clauseSpan.width / 2 - displayOptions.autoScaleToFit.width / 2));
                            clauseSpan.scrollPos = scrollPos * s.svg.scale;
                        }
                    }
                };
                findClauseSpans(s.parseTreeDisplay && s.parseTreeDisplay.tree);
                analysis.sourceSpans[i].analyzed = true;
                analysis.sourceSpans[i].clauseSpans = clauseSpans;
            });

            // insert text-cleaup elisions, analyzed sourceText spans relative to analysis.inputText
            const input = analysis.inputText;
            if (input && input !== analysis.sourceText) {
                let i = 0;
                let ei = analysis.sourceText.length;
                analysis.sourceSpans.forEach((span, si) => {
                    const newClauseSpans = [];
                    span.displayClauseSpans = span.clauseSpans;
                    if (span.clauseSpans) {
                        const source = span.text;
                        span.clauseSpans.forEach((cSpan) => {
                            const curCSpan = { ...cSpan };
                            for (let j = curCSpan.start; j < curCSpan.end; j += 1) {
                                if (input[i] !== source[j]) {
                                    // source & input text differ, insert elision span
                                    const eSpan = { ...curCSpan, tag: 'elision' };
                                    ei = i;
                                    while (ei < input.length && input[ei] !== source[j]) ei += 1;
                                    eSpan.text = input.substring(i, ei);
                                    // reconstruct spans
                                    if (j === cSpan.start) {
                                        newClauseSpans.push(eSpan);
                                    } else {
                                        const newSpan = { ...curCSpan };
                                        newSpan.end = j;
                                        newSpan.text = source.substring(newSpan.start, newSpan.end);
                                        newClauseSpans.push(newSpan);
                                        newClauseSpans.push(eSpan);
                                        curCSpan.start = j;
                                        curCSpan.text = source.substring(curCSpan.start, curCSpan.end);
                                    }
                                    ei += 1;
                                    i = ei;
                                } else {
                                    i += 1;
                                }
                            }
                            if (!curCSpan.added) {
                                curCSpan.text = source.substring(curCSpan.start, curCSpan.end);
                                newClauseSpans.push(curCSpan);
                            }
                        });
                        if (si === analysis.sourceSpans.length - 1 && i < input.length) {
                            newClauseSpans.push({ tag: 'elision', text: input.substring(i) });
                        }
                        // update clause-spans to include any elisions
                        span.displayClauseSpans = newClauseSpans;
                    }
                });
            } else {
                analysis.sourceSpans.forEach(span => { span.displayClauseSpans = span.clauseSpans; });
            }
        },

        guessWordMeanings(s) {
            // make a guess at noun/verb/etc meanings by looking for intersection of possible meanings & words in translated sentence
            if (s.translation) {
                s.mappedPosList.forEach(pos => {
                    if (pos.translation && (!pos.isSetMeaning || pos.notSure)) { // for now, if not sure, let the guesser here have a go - get rid of the '?' if it finds something
                        // has a translation, try to find a better one; run through available meanings & look for matches in the English sentences
                        const ddef = s.displayDefs[pos.morph];
                        if (ddef) {
                            ddef.posList.some(p => {
                                if (pos.label.match(new RegExp(escapeRegex(p.posLabel), 'i'))) // label don't always match exactly
                                    return p.senses.some(sense => {
                                        // try extracted sense
                                        const ss = sense.sense.replace(/[,; ]+/g, ' ');
                                        if (ss && s.translation.search(new RegExp(`(^| )${ss}($|[ .])`, 'i')) >= 0) {
                                            pos.translation = ss;
                                            pos.isSetMeaning = true; // I think!!
                                            pos.notSure = false;
                                            return true;
                                        }
                                        if (ss.split(' ').some(sc => {
                                            if (sc && sc.length > 2 && s.translation.search(new RegExp(`(^| )${sc}($|[ .])`, 'i')) >= 0) {
                                                pos.translation = sc;
                                                pos.isSetMeaning = true; // I think!!
                                                pos.notSure = false;
                                                return true;
                                            }
                                        })) {
                                            return true;
                                        }
                                        // or try individual alternate meanings
                                        return sense.meanings.some(meaning => {
                                            return meaning.shortDef.split(',').some(phrase => {
                                                return phrase.split(' ').some(w => {
                                                    const wc = w.replace(/[,; ]+/g, ' ').replace(/[()]/g, '');
                                                    if (wc && wc.length > 2 && s.translation.search(new RegExp(`(^| )${wc}($|[ .])`, 'i')) >= 0) {
                                                        pos.translation = phrase;
                                                        pos.isSetMeaning = true;
                                                        pos.notSure = false;
                                                        return true;
                                                    }
                                                });
                                            });
                                        });
                                    });
                            });
                        }
                    }
                });
            }
        },

        // force all levels > 0 expanded or collapsed
        zoomParseTree(sentence, collapse) {
            function zoomTree(node) {
                if (node.type === 'tree') {
                    node.collapse = node.level > 1 && collapse;
                    node.children.forEach(c => zoomTree(c));
                }
            }
            if (sentence.treeIndex !== undefined && sentence.parseTrees.length > sentence.treeIndex)
                zoomTree(sentence.parseTrees[sentence.treeIndex].tree, collapse);
        },

        // ------------  library accessors  ---------------------------

        // load IDed pattern and its associated def objects, returns a promive that resolves to the loaded pattern object bundle
        getPattern(id) {
            console.log('getPattern', Localization.getLanguage());
            const callOptions = { data: { id, language: Localization.getLanguage() } };
            return httpAPI(this.apiHost, nlpPaths.getPattern, callOptions);
        },

        // get table of all available externanl references
        allExtRefs() {
            return httpAPI(this.apiHost, nlpPaths.allExtRefs, { method: 'GET' });
        },

    };

    // --- display layout internal helpers -----

    // function oldmMakeLabelLine(pos, displayOptions) {
    //     // gen label as a 2-vector of lines; split label into max 2 lines if needed
    //     let llines = ['', ''];
    //     if (displayOptions.showPartsOfSpeech) {
    //         llines = [pos.label, pos.meaning || (displayOptions.showTranslations && pos.translation) || ''];
    //         // trying new layout:  (line 1) grammar role (in pos.label for now), (line 2) meaning or nuance added

    //         // if (displayOptions.showTranslations && pos.translation) {
    //         //     // translation takes second line if present
    //         //     llines[1] = pos.translation;
    //         // } else if (pos.label.includes(';')) {
    //         //     llines = pos.label.split(';');
    //         //     llines[0] = `${llines[0].trim()};`;
    //         // } else if (pos.label.length > 10) {
    //         //     llines = pos.label.split(' ');
    //         // }
    //         // if (llines.length > 2) {
    //         //     const linesCpy = Array.from(llines);
    //         //     llines = ['', ''];
    //         //     linesCpy.forEach(l => llines[llines[0].length < pos.label.length / 2 ? 0 : 1] += `${l} `);
    //         //     llines[0] = llines[0].trim();
    //         //     llines[1] = llines[1].trim();
    //         // } else if (llines.length === 1)
    //         //     llines.push('');

    //     } else if (displayOptions.showTranslations && pos.translation) {
    //         // translation takes second line if present
    //         llines[1] = pos.meaning || pos.translation;
    //     }
    //     pos.labels = llines.map(l => { return { text: l.trim() }; });
    //     // return index of longest
    //     return llines[0].length > llines[1].length ? 0 : 1;
    // }

    function makeLabelLines(s, pos, displayOptions) {
        // generate POS label lines, one for role, the other meaning, each may have multiple, folded lines
        const labelFold = (input) => {
            let text = input.trim().replace(/([^ ]) *\/ *([^ ])/, '$1 / $2');
            if (text === '') {
                return [];
            } else if (text.length >= 15 && text.indexOf('/') > 0) {
                // first, split long lines at '/', then fold them if needed
                let lines = [];
                const split = [];
                let line = '';
                text.split('/').forEach((s) => {
                    const ss = `${s}_`;
                    if (line.length + ss.length > 15) {
                        split.push(line.trim());
                        line = ss;
                    } else {
                        line += ss;
                    }
                });
                if (line.length > 0)
                    split.push(line.trim().replace(/_$/, ''));
                const foldings = split.map(t => labelFold(t));
                foldings.forEach(f => { lines = lines.concat(f); });
                return lines;
            } else {
                // fold long lines at word breaks
                const lines = [];
                let line = '';
                text = text.replace(/_/g, '/');
                if (text.indexOf(' ') > 0) {
                    text.split(' ').forEach(w => {
                        if (line.length + w.length > 15) {
                            lines.push(line.trim());

                            line = `${w} `;
                        } else
                            line += `${w} `;
                    });
                    if (line.length > 0)
                        lines.push(line.trim());
                } else {
                    lines.push(text.trim());
                }
                return lines;
            }
        };
        //
        let role = [];
        let meaning = [];
        if (displayOptions.showPartsOfSpeech) {
            role = labelFold(pos.label);
            meaning = labelFold(pos.meaning || (displayOptions.showTranslations && pos.translation) || '');
        } else if (displayOptions.showTranslations && pos.translation && pos.translation !== '*translating*') {
            // translation takes second line if present
            meaning = labelFold(pos.meaning || pos.translation);
        }
        //
        pos.labels = meaning.map(m => ({ text: m.trim(), type: 'meaning', className: '' }));
        if (pos.labels.length > 1) s.multiMeaningLines = true;
        if (displayOptions.phraseDisplayMode === 'tree')
            pos.labels = pos.labels.concat(role.map(r => ({ text: r.trim(), type: 'role', className: 'grammar-role' })));
    }

    // -------- SVG metrics helpers ------

    function getLayoutElement(text, cls) {
        // return SVG text element of given class containing given text
        const t = document.querySelector(`#svg-layout-template > text.${cls}`);
        t.textContent = text;
        return t;
    }
    // main export is the NLP-client constructor
    return NlpAPIConstructor;
})();

export const escapeRegex = (string) => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

// also export a consed instance with selected host
// const local = process.env.REACT_APP_LOCAL;
const nlpAPIHost = ''; // always now same as SPA app index.html histing server // local ? 'http://localhost:2001' : 'https://alpha.mirinae.io'; //  'http://localhost:2001' 'https://prototype.mirinae.io';
export const nlpAPI = new NlpAPI(nlpAPIHost);
