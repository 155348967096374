import React from 'react';

const User = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '12'} height={props.height || '14'} viewBox="0 0 12 14">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'} fillRule="nonzero">
                <path d="M0 11.949v-1.006c.037-.747.475-1.411 1.137-1.725 3.12-1.286 6.605-1.286 9.726 0 .662.314 1.1.978 1.137 1.725v1.006c0 .545-.212 1.067-.587 1.451-.376.385-.885.6-1.416.6H2.003c-.53 0-1.04-.215-1.416-.6C.212 13.016 0 12.494 0 11.95zM6 0C4.651 0 3.436.833 2.92 2.11c-.517 1.278-.232 2.75.721 3.728.954.978 2.388 1.271 3.634.742 1.246-.53 2.058-1.777 2.058-3.16 0-.907-.35-1.777-.976-2.418C7.732.36 6.884 0 6 0z" transform="translate(-713 -183) translate(713 183)" />
            </g>
        </g>
    </svg>
);

export default User;