import { zIndex, palette } from '@mirinae/react-ui';

import styled from 'styled-components/macro';

export const Button = styled.button`
    flex-shrink: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    pointer-events: fill;

    svg {
        pointer-events: none;
    }

    &:disabled {
        cursor: default;
    }
`;

export const Popup = styled.div`
    position: absolute;
    right: 0;
    top: 68px;
    z-index: ${zIndex.header + zIndex.float};
    border-radius: 10px;
    background-color: ${palette.primary.white};
    box-shadow: 0px 0px 15px rgba(196, 196, 196, 0.48);
`;