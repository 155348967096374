import React from 'react';
import styled from 'styled-components/macro';

import IconGoogle from '../icons/Google';
import IconTwitter from '../icons/Twitter';
import IconFacebook from '../icons/Facebook';
import IconEmail from '../icons/Email';
import IconApple from '../icons/Apple';

const ButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    padding: 0;

    &:hover {
        border-color: #48a9a6;
    }

    &:disabled {
        filter: grayscale(1);
        opacity: 0.6;
    }
`;

const SNSButton = props => {
    const { theme, onClick, disabled } = props;
    if (theme === 'google') {
        return (
            <ButtonWrapper type="button" onClick={e => onClick && onClick(e)} disabled={disabled} title="Google">
                <IconGoogle />
            </ButtonWrapper>
        );
    } else if (theme === 'twitter') {
        return (
            <ButtonWrapper type="button" onClick={e => onClick && onClick(e)} disabled={disabled} title="Twitter">
                <IconTwitter width="25" height="20" />
            </ButtonWrapper>
        );
    } else if (theme === 'facebook') {
        return (
            <ButtonWrapper type="button" onClick={e => onClick && onClick(e)} disabled={disabled} title="Facebook">
                <IconFacebook width="10" height="20" />
            </ButtonWrapper>
        );
    } else if (theme === 'apple') {
        return (
            <ButtonWrapper type="button" onClick={e => onClick && onClick(e)} disabled={disabled} title="Apple">
                <IconApple width="20" height="20" />
            </ButtonWrapper>
        );
    } else {
        return (
            <ButtonWrapper type="button" onClick={e => onClick && onClick(e)} disabled={disabled} title="email">
                <IconEmail />
            </ButtonWrapper>
        );
    }
};

export default SNSButton;