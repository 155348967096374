import React, { useContext, useEffect } from 'react';

import { UserContext, authAPI } from '@mirinae/react-auth';
import { HeaderComponent } from '@mirinae/react-gnb';

// import { TutorialContext } from '@mirinae/explorer/modules/contexts/tutorial';
import GlobalContext from '@mirinae/explorer/modules/defines/contexts';
import { explorerPaths, authPaths, lessonsPaths, pagePaths } from '@mirinae/shared/defines/paths';
// import { Event } from '@mirinae/explorer/modules/apis/GA3';
import GTM from '@mirinae/shared/apis/GoogleAnalytics';
import Localization, { langsSelectList } from '@mirinae/shared/il8n';
import { getUserType } from '@mirinae/shared/defines/datas';
import { useNavigate } from 'react-router-dom';
import { useAppVersionStore } from '@mirinae/stores/appVersion';
// const _ = require('lodash');

const HeaderContainer = props => {
    const navigate = useNavigate();
    authAPI.setHistory(window.history);
    const isApp = useAppVersionStore(state => state.isApp);

    const [userContext] = useContext(UserContext);
    const [globalData, setGlobalData] = useContext(GlobalContext);

    const handlelogout = () => {
        authAPI.logout().then(() => {
            // force a page-reload to induce guest-user reset
            window.location.href = explorerPaths.home;
        });
        GTM.userAction('logout', { }, 'explorer');
    };

    const handleClickPrefs = (newPrefs) => {
        const updatedPrefs = ({ ...globalData.prefs, ...newPrefs });
        authAPI.updatePrefs(updatedPrefs).then(() => {
            setGlobalData(prev => ({ ...prev, ...updatedPrefs }));
            if (newPrefs.language) {
                Localization.setLanguage(newPrefs.language);
                document.querySelector('html').setAttribute('lang', newPrefs.language);
                GTM.userAction('lang-select', { language: newPrefs.language }, 'explorer');
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        // set language value
        if (userContext.user.preferences) {
            // const prefs = _.merge(globalData.prefs, userContext.user.preferences); // this code has bug
            const prefs = ({ ...globalData.prefs, ...userContext.user.preferences });
            setGlobalData(prev => ({ ...prev, ...prefs }));

            if (prefs.language !== Localization.getLanguage()) {
                Localization.setLanguage(prefs.language);
                GTM.userAction('lang-select', { language: prefs.language }, 'explorer');
            }
            if (prefs.language !== document.querySelector('html').getAttribute('lang')) {
                document.querySelector('html').setAttribute('lang', prefs.language);
            }
        }
    }, [userContext.user && userContext.user.preferences]);

    if (window.location.pathname === explorerPaths.gate
        || window.location.pathname === explorerPaths.ocr
        || window.location.pathname === authPaths.signup
        || window.location.pathname === authPaths.login
        || window.location.pathname === authPaths.emailSignup
        || window.location.pathname === authPaths.accountConfirmed
        || window.location.pathname === authPaths.resetPassword
        || window.location.pathname === authPaths.withdraw
        || window.location.pathname === pagePaths.landing
        || isApp) return null;

    const pathname = window.location.pathname.replace(/\/$/, '');

    return (
        <HeaderComponent
            isApp={isApp} mode={pathname === explorerPaths.home || pathname === explorerPaths.library || pathname === pagePaths.landing || pathname === '' ? 'analyze' : ''}
            user={userContext.user} userType={getUserType(userContext.user)}
            langsList={langsSelectList}
            prefs={globalData.prefs} onClickPrefs={handleClickPrefs}
            onLogout={handlelogout}
            localization={Localization}
        />
    );
};

export default HeaderContainer;
