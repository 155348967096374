import React from 'react';

const LevelNoSmall = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '39'} height={props.height || '11'} viewBox="0 0 39 11">
        <g fill="none" fillRule="evenodd">
            <path fill="#545454" d="M5.5 0h27.015c3.038 0 5.5 2.462 5.5 5.5s-2.462 5.5-5.5 5.5H5.5C2.462 11 0 8.538 0 5.5S2.462 0 5.5 0z" transform="translate(-863 -350) translate(863 350)" />
            <text fill="#FFF" fontFamily="Montserrat-Medium, Montserrat" fontSize="8" fontWeight="400" transform="translate(-863 -350) translate(863 350)">
                <tspan x="6" y="8">No </tspan> <tspan x="19.728" y="8" fontFamily="Montserrat-Bold, Montserrat" fontWeight="bold">Lv.</tspan>
            </text>
        </g>
    </svg>

);

export default LevelNoSmall;