import React, { useState, useContext, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { UserContext } from '@mirinae/react-auth';

import { explorerPaths, ExternalLinks, ExternalRedirector, lessonsPaths } from '@mirinae/shared/defines/paths';
import { localPaths } from '@mirinae/explorer/modules/defines/paths';

import LoadingSpinner from '@mirinae/react-ui/components/spinner/LoadingSpinner';

const Exploration = lazy(() => import(/* webpackChunkName: "Exploration" */ '@mirinae/explorer/pages/Exploration'));
const Reference = lazy(() => import(/* webpackChunkName: "Reference" */ '@mirinae/explorer/pages/Reference'));
const History = lazy(() => import(/* webpackChunkName: "History" */ '@mirinae/explorer/pages/History'));
const OcrVideo = lazy(() => import(/* webpackChunkName: "OcrVideo" */ '@mirinae/explorer/pages/OcrVideo'));
const Glossary = lazy(() => import(/* webpackChunkName: "Glossary" */ '@mirinae/explorer/pages/Glossary'));
const UserFeedback = lazy(() => import(/* webpackChunkName: "UserFeedback" */ '@mirinae/explorer/pages/UserFeedback'));
const LandingPage = lazy(() => import(/* webpackChunkName: "LandingPage" */ '@mirinae/explorer/pages/LandingPage'));

const ExplorerRoutes = () => {

    return (
        <Suspense fallback={LoadingSpinner}>
            <Routes>
                <Route path={localPaths.home} element={<Exploration />} />
                <Route path={localPaths.reference} element={<Reference />} />
                <Route path={localPaths.library} element={<Reference />} />
                <Route path={localPaths.history} element={<History />} />
                <Route path={localPaths.glossary} element={<Glossary />} />
                <Route path={localPaths.userfeedback} element={<UserFeedback />} />
                <Route path={localPaths.userFeedback} element={<UserFeedback />} />
                <Route path={localPaths.ocr} element={<OcrVideo />} />
                <Route path={localPaths.landing} element={<LandingPage />} />

                <Route path={localPaths.lessons} element={<Navigate to={lessonsPaths.home} />} exact />

                <Route path={localPaths.help} element={<ExternalRedirector to={ExternalLinks.support} />} />

                {/*<Route path="*" element={<ExternalRedirector to="/404.html" reload={true}/>} />*/}
                <Route path="*" element={<Exploration />} />
            </Routes>
        </Suspense>
    );
};

export default ExplorerRoutes;
