import React from 'react';
import styled from 'styled-components/macro';

import IconArrowLeft from '../icons/ArrowLeft';

const Button = styled.button`
    height: 21px;
    padding: 0 11px 0 7px;
    font-weight: 700;
    border: 0;
    background: transparent;

    svg {
        transform: rotate(90deg);
        margin-left: 8px;
        vertical-align: middle;
    }
`;

const OptionButton = props => (
    <Button type="button" {...props}>
        {props.children}<IconArrowLeft />
    </Button>
);

export default OptionButton;