import React from 'react';

const LevelProvSmall = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '39'} height={props.height || '11'} viewBox="0 0 39 11">
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" stroke="#E17878" d="M32.515.5c1.38 0 2.63.56 3.536 1.464.904.905 1.464 2.155 1.464 3.536 0 1.38-.56 2.63-1.464 3.536-.905.904-2.155 1.464-3.536 1.464h0H5.5c-1.38 0-2.63-.56-3.536-1.464C1.06 8.13.5 6.88.5 5.5c0-1.38.56-2.63 1.464-3.536C2.87 1.06 4.12.5 5.5.5h0z" transform="translate(-863 -392) translate(863 392)" />
            <text fill="#9341B0" fontFamily="Montserrat-Bold, Montserrat" fontSize="8" fontWeight="bold" transform="translate(-863 -392) translate(863 392)">
                <tspan x="10" y="8">Prov.</tspan>
            </text>
        </g>
    </svg>
);

export default LevelProvSmall;