import React from 'react';
import styled from 'styled-components/macro';

import { size, breakPoint } from '@mirinae/react-ui';
import { useAppVersionStore } from '@mirinae/stores/appVersion';

const Wrapper = styled.div`
    min-height: calc(100vh - ${size.header});
    max-width: ${size.wrap};
    min-width: ${size.mobileWidth};
    margin: 0 auto;

    ${props => !props.isApp && `
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            max-width: 100%;
            min-height: calc(100vh - ${size.header} - ${size.bottom});
        }
    `}
`;

const RouterWrapper = ({ children }) => {
    const isApp = useAppVersionStore(state => state.isApp);

    return (
        <Wrapper isApp={isApp}>
            {children}
        </Wrapper>
    );
}

export default RouterWrapper;