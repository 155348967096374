import React from 'react';

const Email = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '20'} height={props.height || '20'} viewBox="0 0 20 20">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'} fillRule="nonzero">
                <path d="M19 10.23c0-.14 0-.277-.007-.417.003-.314-.01-.629-.04-.942-.222-3.336-2.286-6.27-5.352-7.604C10.43-.308 6.628.127 3.893 2.377 1.715 3.992.405 6.523.345 9.235c-.23 4.06 2.232 7.791 6.058 9.174.278.1.56.185.848.256.133.035.253.068.377.106l.232.072L9.115 19h.424c.328.002.656-.013.982-.044.535-.062 1.066-.158 1.59-.285.367-.082.684-.311.878-.634.28-.485.236-1.092-.113-1.53-.348-.439-.93-.62-1.465-.455-3.434.935-6.993-1.007-8.064-4.4-.597-1.934-.275-4.036.874-5.702C5.371 4.283 7.22 3.235 9.24 3.105c2.112-.139 4.162.744 5.511 2.374 1.35 1.63 1.834 3.81 1.302 5.858-.045.209-.14.403-.278.566-.286.257-.702.31-1.043.133-.329-.184-.588-.471-.737-.817-.61-1.254-.162-3.128-.091-4.486.035-.692-.497-1.281-1.19-1.317-.44-.021-.857.194-1.095.564-1.008-.617-2.218-.814-3.37-.55-1.386.316-2.538 1.274-3.101 2.58-.564 1.306-.47 2.802.252 4.027.764 1.337 2.175 2.174 3.715 2.202 1.028.023 2.034-.297 2.861-.908.904 1.06 2.275 1.602 3.66 1.448 1.4-.197 2.562-1.179 2.99-2.526.06-.164.103-.333.147-.504l.068-.266L19 10.23zm-7.987-.363c-.043.836-.671 1.524-1.5 1.645-.828.12-1.626-.361-1.906-1.15-.28-.789.037-1.666.756-2.094.719-.429 1.64-.29 2.201.332.313.345.475.801.45 1.267z" transform="translate(-379 -717) translate(379 717) translate(1 1)" />
            </g>
        </g>
    </svg>
);

export default Email;